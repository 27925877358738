import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
    redirectUnauthorizedTo,
    redirectLoggedInTo,
    canActivate,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/auth/login/login.module').then((m) => m.LoginPageModule),
        ...canActivate(redirectLoggedInToHome),
    },
    {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomePageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'registration',
        loadChildren: () => import('./modules/auth/registration/registration.module').then((m) => m.RegistrationPageModule),
        ...canActivate(redirectLoggedInToHome),
    },
    {
        path: 'email-verification',
        loadChildren: () => import('./modules/auth/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
        ...canActivate(redirectLoggedInToHome),
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfilePageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'addresses',
        loadChildren: () => import('./modules/addresses/addresses.module').then(m => m.AddressesPageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'payment-methods',
        loadChildren: () => import('./modules/payment-methods/payment-methods.module').then(m => m.PaymentMethodsPageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'billing',
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingPageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'orders-history',
        loadChildren: () => import('./modules/orders-history/orders-history.module').then(m => m.OrdersHistoryPageModule),
        ...canActivate(redirectUnauthorizedToLogin),
    },
    {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsPageModule)
    },
    {
        path: 'help',
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpPageModule)
    },
    // {
    //     path: 'evaluation',
    //     loadChildren: () => import('./modules/calification/evaluation/evaluation.module').then( m => m.EvaluationPageModule)
    //   },
    //   {
    //     path: 'modal-stiker',
    //     loadChildren: () => import('./modules/calification/modal-stiker/modal-stiker.module').then( m => m.ModalStikerPageModule)
    //   },
    
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
 


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
