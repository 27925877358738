import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/share/services/auth.service';
import { AddressesService, IAddressItem } from 'src/app/share/services/addresses.service';
import { BusinessesService } from '../../services/businesses.service';
@Component({
    selector: 'app-address-selector',
    templateUrl: './address-selector.component.html',
    styleUrls: ['./address-selector.component.scss'],
})
export class AddressSelectorComponent implements OnInit {
    addresses!: IAddressItem[];
    activeAddressUid!: string;
    
    constructor(
        private authService: AuthService,
        private addressesService: AddressesService,
        private businessesService: BusinessesService,
    ) {
        
    }
    
    async ngOnInit() {
        this.addresses = await this.addressesService.list(this.authService.uid);
        this.activeAddressUid = this.addressesService.currentAddressItemUid;
        this.businessesService.filters = {
            position: this.addressesService.currentAddress.position,
        }
    }

    async changeActiveAddress(e:any) {
        await this.addressesService.setActiveItem(this.authService.uid, e.detail.value);
        this.businessesService.page = 0;
        this.businessesService.filters = {
            position: this.addressesService.currentAddress.position,
            page: 0,
        }
    }
}
