import { Injectable } from '@angular/core';

import { EndpointsService } from './endpoints.service';
import { HttpService } from './http.service';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _count = 0

    constructor(
        private httpService: HttpService,
        private endpoints: EndpointsService,
    ) { }

    get count() {
        return this._count;
    }
    
    list(userId: string) {
        return this.httpService.get(this.endpoints.notificationList.replace('{userId}', userId)).pipe(map((notifications:any) => {
            let count = 0;
            notifications.data.forEach( (notification:any) => {
                if (notification.status.id == 1) {
                    count++;
                }
            })
            this._count = count;
            return notifications;
        }));
    }

    seen(notificationId: string, seen:boolean) {
        if (!seen) {
            this._count--;
        }
        return this.httpService.put(this.endpoints.notificationSeen.replace('{notificationId}', notificationId));
    }

    async delete(notificationId: string) {
        await firstValueFrom(this.httpService.delete(this.endpoints.notificationDelete.replace('{notificationId}', notificationId)));
    }
}
