import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';
import { firstValueFrom } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { ConfirmController } from '../controllers/confirm.controller';
import { PersonalizersService } from './personalizers.service';
import { Auth } from '@angular/fire/auth';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AppointmentsService {
	private _schedules = <any>{};
	private _appointments = <any>{};

	get appointments() {
		return this._appointments;
	}

	constructor(
		private auth: Auth,
		private httpService: HttpService,
		private endpoints: EndpointsService,
		private personalizersService: PersonalizersService,
		private loadingController: LoadingController,
		private confirmController: ConfirmController,
	) { }

	fetch(ownerId: string, businessId: string, date: string, key: string) {
		if (key === 'days') {
			return this.fetchDays(ownerId, businessId, date);
		}
		return this.fetchHours(ownerId, businessId, date);
	}

	fetchDays(ownerId: string, businessId: string, date: string) {
		return this.httpService.get(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId) + '/month/' + date);
	}

	fetchHours(ownerId: string, businessId: string, date: string) {
		return this.httpService.get(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId) + '/hours/' + date);
	}

	addDataToSchedule(ownerId: string, businessId: string, date: string, key: string, data: any) {
		return;
		if (!this._schedules[ownerId]) {
			this._schedules[ownerId] = {};
		}
		if (!this._schedules[ownerId][businessId]) {
			this._schedules[ownerId][businessId] = {};
		}
		if (!this._schedules[ownerId][businessId][date]) {
			this._schedules[ownerId][businessId][date] = {};
		}
		this._schedules[ownerId][businessId][date][key] = data;
	}

	async getScheduleData(ownerId: string, businessId: string, date: string, key: string) {
		let ret = [];

		const loading = await this.loadingController.create();
		await loading.present();

		if (this._schedules[ownerId] && this._schedules[ownerId][businessId] && this._schedules[ownerId][businessId][date] && this._schedules[ownerId][businessId][date][key]) {
			ret = this._schedules[ownerId][businessId][date][key];
		}
		else {
			const response: any = await firstValueFrom(this.fetch(ownerId, businessId, date, key));
			const data = response.data;
			// Add the days to the schedule object
			this.addDataToSchedule(ownerId, businessId, date, key, data);
			ret = data;
		}

		if (ret.length === 0) {
			await this.confirmController.createAndPresent({
				message: "No se pudo cargar la disponibilidad de horarios",
			});
		}
		loading.dismiss();

		return ret;
	}

	async checkAvailability(ownerId: string, businessId: string, date: string, hour: string, productId: string) {
		const body: any = {
			appointment: {
				appointmentDate: date,
				appointmentStartTime: hour,
				servicesProducts: [
					{
						id: productId
					}
				]
			}
		};
		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.post(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId) + '/valid-end-date', body));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				return true;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo verificar la disponibilidad de la cita",
			});
		}
		return false;
	}

	async saveAppointment(ownerId: string, businessId: string, data: any) {

		// let headers = new HttpHeaders(
		// 	{
		// 		'userId': this.auth.currentUser!.uid,
		// 		'businessId': businessId,
		// 		'receiveNotifications': 'true'
		// 	}
		// );

		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.post(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId), data));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				this._appointments[response.data.servicesProducts[0].id] = response.data;
				return response.data;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo guardar la cita, intentalo nuevamente",
			});
		}
	}

	async updateAppointment(ownerId: string, businessId: string, date: string, hour: string, productId: string) {
		const body: any = {
			appointment: this._appointments[productId],
		};
		body.appointment.appointmentDate = date;
		body.appointment.appointmentStartTime = hour;

		let headers = new HttpHeaders(
			{
				'userId': this.auth.currentUser!.uid,
				'businessId': businessId,
				'receiveNotifications': 'true'
			}
		);
		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.put(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId), body, { headers: headers }));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				this._appointments[response.data.servicesProducts[0].id] = response.data;
				return response.data;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo actualizar la cita, intentalo nuevamente",
			});
		}
	}


	async updateAppointmentChisco(appointmentId: string, status: string) {

		let headers = new HttpHeaders(
			{
				'userId': this.auth.currentUser!.uid,
				'receiveNotifications': 'true'
			}
		);

		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.put(this.endpoints.appointmentUpdate.replace('{appointmentId}', appointmentId).replace('{statusId}', status), null, { headers: headers }));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				return response.data;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo actualizar la cita, intentalo nuevamente",
			});
		}

	}

	async cancelAppointment(productId: string) {
		const appointment = this._appointments[productId];
		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.delete(this.endpoints.appointment.replace('{ownerId}', appointment.ownerId).replace('{businessId}', appointment.bussinesId) + '/' + appointment.id));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				delete this._appointments[productId];
				return true;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo eliminar la cita, intentalo nuevamente",
			});
		}
		return false;
	}

	async deleteAllAppointments() {
		for (const key in this._appointments) {
			const appointment = this._appointments[key];
			let response = await this.cancelAppointment(appointment.servicesProducts[0].id);
			if (!response) {
				return false;
			}
		}
		this._appointments = {};
		return true;
	}

	async changeQuantity(ownerId: string, businessId: string, productId: string, quantity: number, price: number) {
		const body: any = {
			appointment: this._appointments[productId],
		};
		const servicesProducts = []

		for (let i = 0; i < quantity; i++) {
			servicesProducts.push({
				id: productId,
			});
		}

		body.appointment.servicesProducts = servicesProducts;
		body.appointment.total = (price * quantity) + this.personalizersService.getPersonalizersTotalAmount(productId);
		body.appointment.appointmentEndTime = null;

		let response: any = {}
		try {
			response = await firstValueFrom(this.httpService.put(this.endpoints.appointment.replace('{ownerId}', ownerId).replace('{businessId}', businessId), body));
		}
		catch (e: any) {
			response = e.error;
		}
		if (response) {
			if (response.success) {
				this._appointments[response.data.servicesProducts[0].id] = response.data;
				return response.data;
			}
			else {
				await this.confirmController.createAndPresent({
					message: response.message,
				});
			}
		}
		else {
			await this.confirmController.createAndPresent({
				message: "No se pudo cambiar la cantidad de producto(s), intentalo nuevamente",
			});
		}
	}
}