import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { EndpointsService } from './endpoints.service';
import { HttpService } from './http.service';
import { Subject, map } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { ProfileService } from './profile.service';
import { ConfirmController } from '../controllers/confirm.controller';
import { Auth } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class BusinessesService {

    page: number = 0;

    private filtersSource = new Subject<any>();

    private _filters = {
        search: '',
        category: '',
        position: '',
    };

    filters$ = this.filtersSource.asObservable();

    set filters(filters: any) {
        this._filters = {
            ...this._filters,
            ...filters,
        };
        this.filtersSource.next(this._filters);
    }

    get filters() {
        return this._filters;
    }

    constructor(
        private httpService: HttpService,
        private endpoints: EndpointsService,
        private auth: Auth,
        private profileService: ProfileService,
        private confirmController: ConfirmController,
    ) { }

    fetch(filters?: any) {
        const body = {
            "position": filters && filters['position'] || "",
            "businessTypeId": filters && filters['category'] || "",
            "predictive": filters && filters['search'] || "",
            "page": filters && filters['page'] || "0",
            "size": "20"
        }


        return this.httpService.post(this.endpoints.businesses, body).pipe(
            // Filter out the businesses having the flag "businessApplication" false
            map((response: any) => {
                const data = response.data;
                const filteredData = data.filter((business: any) => business.businessApplication);
                response.data = filteredData;
                response.data = response?.data?.map((business: any) => {
                    if (business.color === '' || business.color === null) {
                        business.color = 'primary';
                    }          
                    business.businessIdPraga = business.businessId ?? '';                   
                    return business;
                });
                return response.data;
            })
        )
    }

    nextPage() {
        this.page++;
        this.filters = {
            page: this.page,
        };
    }

    prevPage() {
        if (this.page === 0) return;
        this.page--;
        this.filters = {
            page: this.page,
        };
    }

    async registerQualify(ownerId: any, businessId: any,
        sale: any, score: any, obj:any) {
          
        const response: any = await firstValueFrom(this.httpService.put(this.endpoints.qualify.replace('{ownerId}', ownerId).
            replace('{businessId}', businessId).replace('{saleId}', sale).replace('{scoreId}', score), obj));
        if (response && response.success) {
            if (response.success) {
                return response.data;
            }
            else {
                await this.confirmController.createAndPresent({
                    message: response.message,
                });
            }
        }
    }

    async obtieneLogoQualify(ownerId: any, businessId: any){
        const response: any = await firstValueFrom(this.httpService.get(this.endpoints.scoreImage.replace('{ownerId}', ownerId).
        replace('{businessId}', businessId)));

    if (response && response.success) {
        if (response.success) {
            return response.data;
        }
        else {
            await this.confirmController.createAndPresent({
                message: response.message,
            });
        }
    }
    }

}
