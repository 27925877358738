
export enum dataCustom {
  empty = 'empty'
}


export enum typeEvent {
  negocio = 'Negocio',
  btnEmptyCart = 'Botón vaciar carrito',
  btnDeleteProduct = 'Botón Eliminar producto',
  btnchangeAppointment = 'Botón  editar mi cita',
  btnSchedule = 'Botón  guardar cita',
  btnDate = 'Botón cambiar fecha',
  btnTimer = 'Botón elegir horario',
  btnAgregar = 'Botón agregar',
  btnIncrement  = 'Botón (+)',
  btnDecrement = 'Botón (-)',
  btnCalendar = 'Botón calendario agendar tu cita',
  optProfile = 'Opción perfil',
  optAaddresses = 'Opción domicilio',
  optPayment = 'Opción métodos de pago ',
  optBilling = 'Opción datos fiscales ',
  optHistory = 'Opción historial de pedidos ',
  optNotify = 'Opción notificaciones',
  optTerm = 'Opción términos y condiciones',
  optLogout = 'Opción  cerrar sesión', 
  deleteNotify = 'Eliminar notificación',
  detailNotify = 'Ver detalle de notificación ',
  readNotify = 'Marcar notificación como leida',
  btnNotify = 'Botón notificaciones',
  btnCargar = 'Botón cargar saldo',
  clearFilter = 'Limpiar filtro',
  btnPay = 'Botón Pagar',
  viewWallet = 'Consultar mi tarjeta',
  btnNuevoDom = 'Botón agregar domicilio',
  sesion = 'Botón iniciar sesión',
  entrar = 'Botón entrar',
  btnGuardar = 'Botón guardar',
  btnRegistro = 'Botón registrarse',
  registro = 'Registro',
  radioTerminos = 'Radio button aceptar términos y condiciones',
  terminos = 'Términos y condiciones',
  aviso = 'Aviso de privacidad',
  abremodal = 'Botón nuevo personalizador',
  btnRecovery = 'Botón recupera tu constraseña',
  domicilio = 'Pantalla Domicilio',
  domicilioForm = 'Pantalla para agregar domicilio',
  selCategory = 'Seleccionar tipo de negocio',
  btnBack = 'Botón regresar',
  uploadImage = 'Botón carga tu logo',
  btnReenviar = 'Botón reenviar correo de verificación',
  btnVerify = 'Ya verifiqué mi correo',
  btnNext = 'Botón siguiente',
  btnIzq = 'Botón carousel izquierda',
  btnDer = 'Botón carousel derecha',
  btnPreguntas = 'Botón preguntas frecuentes',
  btnRegister = 'Botón Registro de negocio',
  registrate = 'Botón Regístrate',
  openCalendar = 'Abre calendario',
  olvideContrasena = 'Olvidé mi contraseña',
  consulta = 'Consulta',
  radioBtn = 'Radio button agotado',
  verPassword = 'Ver Password',
  eliminar = 'Botón eliminar aceptar',
  btnCancel = 'Botón eliminar cancel',
  btnExpand = 'Botón lista detalle',
  guardado = 'Botón guardar',
  modificar = 'Botón modificar',
  updateEmail = 'actualiza correo eléctronico',
  actualiza = 'Botón actualizar',
  carrito = 'carrito_compra',
  radioClient = 'Radio button El cliente puede seleccionar solo una opción ',
  radioAmount = 'Radio button Cantidad máxima de personalizadores que puede seleccionar ',
  btnShopping = 'Botón nueva venta',
  btnPlus = 'Botón (+)',
  btnMinus = 'Botón (-)',
  menuCompras = 'Menú compras',
  menuDatos = 'Menú datos del negocio',
  radioMostrar = 'Radio button mostrar en la aplicación',
  btnPayment = 'Botón proceder a pago',
  pago = 'Pago ',
  pagoApp = 'Pago App',
  btnShoppingCancel = 'Botón cancelar comprar (cancel)',
  btnShoppingAcept = 'Botón cancelar comprar (aceptar)',
  scanQr = 'Escaneo código qr',
  rechargeBalance = 'Botón recargar saldo',
  finalizaVenta = 'Botón finalizar venta',
  selectAmount = 'Seleccionar monto ',
  btnAgendar = 'Botón agendar cita',
  btnVenta = 'Nueva venta',
  menuAgenda = 'Menú agenda',
  citaProxima = 'Citas próximas',
  citaVencidas = 'Citas vencidas',
  uploadImg = 'Carga imagen',
  btnPreview = 'Botón vista previa',
  btnNextImg = 'Botón siguiente imagen',
  btnPrevImg = 'Botón anterior imagen',
}

export enum dataset {
  home = 'home',
  appointment = 'appointment',
  business = 'businessApp',
  catalogue = 'catalogue',
  category = 'category',
  customization_group = 'customization_group',
  customizer = 'customizer',
  products = 'services_products',
  main = 'main',
  billing = 'billing',
  profile = 'profile',
  general = 'general',
  address = 'addressApp',
  login = 'loginApp',
  notifications = 'notificationsApp',
  shopingCart = 'shoppingCartApp',
  sales = 'sales',
}


export enum pageTitle {
  cart = 'Carrito',
  shoppingCart = 'Carrito de compras',
  menu = 'menu',
  myWallet = 'Mi tarjeta',
  wallet = 'Cartera',
  businessSelect = 'bussines-select',
  business = 'bussines',
  terminos = 'Términos y condiciones',
  aviso = 'Aviso de privacidad',
  address = 'Domicilio',
  notify = 'Notificaciones',
  formAddress = 'Formulario para agregar domicilio',
  recoveryPass = 'Recuperar contraseña',
  registro = 'Registro de usuario',
  verificacionMail = 'Verificación de email',
  principal = 'Principal',
  catalogs = 'catalogs',
  businesSelect = 'businesSelect',
  emailVerification = 'emailVerification',
  forgotPassword = 'forgot-password',
  login = 'login',
  categories = 'categories',
  groupsCustomizers = 'groupsCustomizers',
  customizer = 'customizers_modal',
  products = 'products&Services',
  dataBusiness = 'dataBusiness',
  home = 'home',
  schedule = 'schedule',
  expiredSchedule = 'expiredSchedule',
  upcomingSchedule = 'upcomingSchedule',
  buyingProducts = 'buyingProducts',
 
  profile = 'Perfil',
}

