import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
    provideAnalytics,
    getAnalytics,
    ScreenTrackingService,
    UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';

import { ConfirmController } from 'src/app/share/controllers/confirm.controller';

import { AuthService } from 'src/app/share/services/auth.service';
import { ProfileService } from 'src/app/share/services/profile.service';
import { PaymentMethodsService } from './share/services/payment-methods.service';
import { AddressesService } from './share/services/addresses.service';
import { EndpointsService } from './share/services/endpoints.service';
import { HttpService } from './share/services/http.service';
import { CategoriesService } from './share/services/categories.service';
import { CartService } from './share/services/cart.service';
import { BusinessService } from './share/services/business.service';
import { GeoCodingService } from './share/services/geo-coding-service.service';
import { ProductsService } from './share/services/products.service';
import { PersonalizersService } from './share/services/personalizers.service';
import { AppointmentsService } from './share/services/appointments.service';
import { NotificationsService } from './share/services/notifications.service';
import { MenuComponent } from './modules/menu/menu.component';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { PrivacyPolicyModule } from "./share/components/privacy-policy.module";
import { TermsAndConditionsModule } from "./share/components/terms-and-conditions.module";


@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
    
   
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ScreenTrackingService,
        UserTrackingService,
        ConfirmController,
        AuthService,
        ProfileService,
        PaymentMethodsService,
        AddressesService,
        EndpointsService,
        HttpService,
        CategoriesService,
        CartService,
        BusinessService,
        GeoCodingService,
        CartService,
        ProductsService,
        PersonalizersService,
        AppointmentsService,
        NotificationsService,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        provideFirebaseApp(() => {
            const app = initializeApp(environment.firebase);
            if (Capacitor.isNativePlatform()) {
                initializeAuth(app, {
                    persistence: indexedDBLocalPersistence
                });
            }
            return app;
        }),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideMessaging(() => getMessaging()),
        providePerformance(() => getPerformance()),
        provideStorage(() => getStorage()),
        AngularFireAnalyticsModule,
        AngularFireModule.initializeApp(environment.firebase),
        PrivacyPolicyModule,
        TermsAndConditionsModule
    ]
})
export class AppModule {}
