import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';


@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    private appointmentCategoriesId = <any>[ ];
    private _categories = <any>[ ];

    set categories (categories: any) {
        this._categories = categories;
    }
    
    constructor(
        private httpService: HttpService,
        private endpoints: EndpointsService,
    ) { }

    fetch() {
        return this.httpService.get(this.endpoints.categories);
    }

    addAppointmentCategory(categoryId: string) {
        if(!this.appointmentCategoriesId.includes(categoryId)) {
            this.appointmentCategoriesId.push(categoryId);
        }
    }

    isAppointmentCategory(categoryId: string) {
        return this.appointmentCategoriesId.includes(categoryId);
    }

    categoryName(categoryId: string) {
        // Search for the category name in the categories array
        const category = this._categories.find( (category: any) => category.id === categoryId);
        if(!category) {
            return '';
        }
        return category.name;
    }
}
