import { Injectable } from '@angular/core';
import {
    Firestore,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    updateDoc,
    getCountFromServer,
 } from '@angular/fire/firestore';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';

export interface IAddressItem {
    uid: string;
    position: string;
    active: boolean;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
};

interface IAddress {
    active: boolean;
    position: string;
    address1: string;
    exterior_number: string;
    interior_number: string;
    zip: string;
    state: string;
    county: string;
    neighborhood: string;
};

@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    currentAddressItemUid = '';
    currentAddress!: IAddress;
    
    constructor(
        private firestore: Firestore,
        private httpService: HttpService,
        private endpoints: EndpointsService,
    ) {
        this.clearCurrentAddress();
    }

    clearCurrentAddress() {
        this.currentAddress = {
            active: false,
            position: '',
            address1: '',
            exterior_number: '',
            interior_number: '',
            zip: '',
            state: '',
            county: '',
            neighborhood: '',
        }
        this.currentAddressItemUid = '';
    }

    getAddressFromZipCode(zipCode: string) {
        return this.httpService.get(this.endpoints.address + '/' + zipCode);
    }
    
    async register(userRegistrationId:string, registration: any) {
        const firestore = this.firestore;

        this.currentAddress = {
            active: registration.active,
            position: registration.position,
            address1: registration.address1,
            exterior_number: registration.exterior_number,
            interior_number: registration.interior_number,
            zip: registration.zip,
            state: registration.state,
            county: registration.county,
            neighborhood: registration.neighborhood,
        }
        
        try {
            if (this.currentAddressItemUid) {
                await updateDoc(doc(firestore, `userProfile/${userRegistrationId}/addresses`, this.currentAddressItemUid), registration);
                // Set active item and ensure it is the only active item.
                if (this.currentAddress.active){
                    await this.setActiveItem(userRegistrationId, this.currentAddressItemUid);
                }
            } else {
                const docRef = await addDoc(collection(firestore, `userProfile/${userRegistrationId}/addresses`), this.currentAddress);
                // Set active item and ensure it is the only active item.
                if (registration.active){
                    await this.setActiveItem(userRegistrationId, docRef.id);
                }
            }

            return true;
        } catch(e) {
            console.log('Error saving address: EA01. ', e);
            return false;
        }
    }

    async list(userRegistrationId:string) {
        try {
            const addressesDocs =  await getDocs(collection(this.firestore, `userProfile/${userRegistrationId}/addresses`))
            let addresses = new Array<IAddressItem>();
            addressesDocs.forEach(addressDoc => {
                if (addressDoc.exists()) {
                    const address = {
                        uid: addressDoc.id,
                        active: addressDoc.get('active'),
                        position: addressDoc.get('position'),
                        address1: `${addressDoc.get('address1')} ${addressDoc.get('exterior_number')}-${addressDoc.get('interior_number')}`,
                        address2: `${addressDoc.get('neighborhood')}`,
                        address3: `${addressDoc.get('county')}, ${addressDoc.get('state')}`,
                        address4: `${addressDoc.get('zip')}`,
                    }
                    addresses.push(address);
                    if (address.active) {
                        this.currentAddress = {
                            active: addressDoc.get('active'),
                            position: addressDoc.get('position'),
                            address1: addressDoc.get('address1'),
                            exterior_number: addressDoc.get('exterior_number'),
                            interior_number: addressDoc.get('interior_number'),
                            zip: addressDoc.get('zip'),
                            state: addressDoc.get('state'),
                            county: addressDoc.get('county'),
                            neighborhood: addressDoc.get('neighborhood'),
                        }
                        this.currentAddressItemUid = addressDoc.id;
                    }
                }
            });
            return addresses;
        } catch(e) {
            console.log("Error reading addresses: PM01.", e)
            return [];
        }
    }

    async fetchAddressToEdit(userRegistrationUid: string, addressUid: string) {
        try {
            const addressDoc = await getDoc(doc(this.firestore, `userProfile/${userRegistrationUid}/addresses`, addressUid));
            if (addressDoc.exists()) {
                this.currentAddress = {
                    active: addressDoc.get('active'),
                    position: addressDoc.get('position'),
                    address1: addressDoc.get('address1'),
                    exterior_number: addressDoc.get('exterior_number'),
                    interior_number: addressDoc.get('interior_number'),
                    zip: addressDoc.get('zip'),
                    state: addressDoc.get('state'),
                    county: addressDoc.get('county'),
                    neighborhood: addressDoc.get('neighborhood'),
                }

                this.currentAddressItemUid = addressDoc.id;
                return true
            }
        } catch(e) {
            console.log('Error reading address: EA02. ' + e)
        }

        return false;
    }

    async deleteAddress(userRegistrationUid: string, addressUid: string) {
        try {
            await deleteDoc(doc(this.firestore, `userProfile/${userRegistrationUid}/addresses`, addressUid));
            return true;
        } catch(e) {
            console.log('Error deleting address: PM03. ' + e);
        }
        return false;
    }

    async getAddressesCount(userRegistrationId:string){
        const coll = collection(this.firestore, `userProfile/${userRegistrationId}/addresses`);
        const snapshot = await getCountFromServer(coll);
        return (snapshot.data().count);
    }

    async setActiveItem(userRegistrationId:string, currentAddressItemUid:string) {
        const firestore = this.firestore;
        const docRefPath =  `userProfile/${userRegistrationId}/addresses`;

        try {
            const addressesRead =  await getDocs(collection(this.firestore, docRefPath))
            addressesRead.forEach(addressDoc => {
                if (addressDoc.exists()) {
                    if (currentAddressItemUid == addressDoc.id){
                        updateDoc(doc(firestore,docRefPath, addressDoc.id), {active: true});
                        this.currentAddress = {
                            active: addressDoc.get('active'),
                            position: addressDoc.get('position'),
                            address1: addressDoc.get('address1'),
                            exterior_number: addressDoc.get('exterior_number'),
                            interior_number: addressDoc.get('interior_number'),
                            zip: addressDoc.get('zip'),
                            state: addressDoc.get('state'),
                            county: addressDoc.get('county'),
                            neighborhood: addressDoc.get('neighborhood'),
                        }
                        this.currentAddressItemUid = addressDoc.id;
                    } else {
                        updateDoc(doc(firestore, docRefPath, addressDoc.id), {active: false});
                    }
                }
            });
            return true;

        }catch(e){
            console.log("Error Setting active address: PM02. ", e)
            return false;
        }
    }
}
