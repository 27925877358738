import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PersonalizersService {
	
	private _personalizers: any = {};
	
	constructor() { }
	
	get personalizers() {
		return this._personalizers;
	}

	addPersonalizer(productId: string, groupId: string, personalizer: any) {
		if(!this._personalizers[productId]){
			this._personalizers[productId] = {};
		}
		if(!this._personalizers[productId][groupId]){
			this._personalizers[productId][groupId] = {};
		}
		const personalizerId = personalizer.id;
		this._personalizers[productId][groupId][personalizerId] = personalizer;
	}

	getPersonalizers(productId: string, addAppointment: boolean = false) {
		let personalizers = <any>[];
		if(this._personalizers[productId]){
			Object.keys(this._personalizers[productId]).forEach(group => {
				Object.keys(this._personalizers[productId][group]).forEach(personalizer => {
					if(addAppointment){
						personalizers.push({
							id: personalizer,
						});
					}else {
						personalizers.push(this._personalizers[productId][group][personalizer]);
					}
				});
			});
		}
		return personalizers;
	}

	getPersonalizersTotalAmount(productId: string) {
		let total = 0;
		if(this._personalizers[productId]){
			Object.keys(this._personalizers[productId]).forEach(group => {
				Object.keys(this._personalizers[productId][group]).forEach(personalizer => {
					total += this._personalizers[productId][group][personalizer].total;
				});
			});
		}
		return total;
	}
}
