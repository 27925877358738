import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Business } from './wallet.service';
import {
    Auth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    UserCredential,
    onAuthStateChanged,
    User,
    NextOrObserver,
    updateEmail,
} from '@angular/fire/auth';
import { EndpointsService } from './endpoints.service';
import { Firestore, collectionData, collectionGroup, query, where } from '@angular/fire/firestore';

interface ILoginCredentials {
    email: string;
    password: string;
};

interface IRegisterResult {
    success: boolean;
    errorCode?: string;
};

interface IVirtualCard {
    cardId: string;
    userId: string;
    businessId: string;
    description?: string;
    currency: string;
    status: string;
    balance: number;
    userTransactionId: string;
    mitId?: string;
    amount: number;
};


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _userCredential!: UserCredential;
    get recentLogin() {
        return !!this._userCredential;
    }

    private _user!: User;
    set user(_user: User) {
        this._user = _user;
    }
    get uid() {
        return this._user && this._user.uid;
    }
    get email() {
        return this._user && this._user.email;
    }

    constructor(
        private auth: Auth,
        private httpService: HttpService,
        private enpointService: EndpointsService,
        private firestore: Firestore
    ) { }

    onAuthStateChangedRegister(handler: NextOrObserver<User>) {
        onAuthStateChanged(this.auth, handler)
    }

    async register(registration: ILoginCredentials): Promise<IRegisterResult> {
        try {
            this._userCredential = await createUserWithEmailAndPassword(this.auth, registration.email, registration.password);
            this._user = this._userCredential.user;
            await sendEmailVerification(this._userCredential.user);

            return {
                success: true,
            }
        } catch (e: any) {
            return {
                success: false,
                errorCode: e['code'],
            }
        }
    }

    async resendEmailVerification() {
        try {
            await sendEmailVerification(this._userCredential.user);
        } catch (e) {
            return false
        }
        return true
    }

    async sendPasswordResetEmail(email: string) {
        try {
            await sendPasswordResetEmail(this.auth, email);
        } catch (e) {
            return false
        }
        return true
    }

    async login(credentials: ILoginCredentials) {
        try {
            this._userCredential = await signInWithEmailAndPassword(this.auth, credentials.email, credentials.password);
            this._user = this._userCredential.user;
            return this._userCredential;
        } catch (e) {
            return null;
        }
    }

    async logout() {
        await signOut(this.auth);
    }

    async updateEmail(email: string): Promise<IRegisterResult> {
        try {
            await updateEmail(this._user, email);
            await sendEmailVerification(this._user);
            return {
                success: true,
            }
        } catch (e: any) {
            return {
                success: false,
                errorCode: e['code'],
            }
        }
    }


    //generate virtual card
    async createCard(businessId: string): Promise<any> {
        const promise = new Promise<any>((resolve) => {
            let card = {
                userId: this._user.uid,
                businessId: businessId,
                description: "Tarjeta Baguu",
                currency: "MXN",
                status: "enabled",
                balance: 0.00,
                userTransactionId: this._user.uid,
                mitId: "NA",
                amount: 0.00
            }

            this.httpService.post(this.enpointService.createCard, card).subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    console.error('Error en la solicitud HTTP:', error);
                    resolve(null);
                }
            );
        });

        return promise;
    }

    //busca si el usuario que intenta logearse existe en el perfil
    findUserProfile(ownerId: string, isAdmin: boolean): Promise<any> {
        const promise = new Promise<boolean>((resolve, reject) => {
            console.log('findUserProfile :::::::::>>>>> ', ownerId);
            let profile = '';
            if (isAdmin) {
                profile = 'adminProfile';
            } else {
                profile = 'businessProfile';
            }
            const profiles = query(
                collectionGroup(this.firestore, profile),
                where('registrationId', '==', ownerId)
            );

            collectionData(profiles, { idField: 'registrationId' }).subscribe(
                (data: any) => {
                    resolve(data);
                }
            );
        });

        return promise;
    }

}
