import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrReaderService {

  private isScanRequired$ = new Subject<boolean>();

  set isScanRequired(required:boolean) {
    this.isScanRequired$.next(required);
  }

  get isScanRequiredObs(): Observable<boolean> {
    return this.isScanRequired$.asObservable();
  }

}
