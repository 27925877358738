import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeoCodingService {
    private geoCodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
    
    constructor(private http: HttpClient) {}
    
    getLatLongFromAddress(address: string): Observable<any> {
        const params = {
            address,
            key: 'AIzaSyAv5-i1-gZNYTsK6FW2s0vUWWvuRRizxKs'
        };
        return this.http.get<any>(this.geoCodingUrl, { params }).pipe(
            map(response => {
                const lat = response.results[0].geometry.location.lat;
                const lng = response.results[0].geometry.location.lng;
                return `${lng} ${lat}`;
            })
            );
        }
    }
    