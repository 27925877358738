import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';


import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ShareUIComponentModule } from './ui.module';

@NgModule({
  declarations: [TermsAndConditionsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ShareUIComponentModule
  ],
  exports:[TermsAndConditionsComponent],
})
export class TermsAndConditionsModule { }
