import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';
import { BusinessService } from './business.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private filterSource = new Subject<any>();
    private catalogSource = new Subject<any>();
    private categorySource = new Subject<any>();
    private productSource = new Subject<any>();
    private _activeCatalog = 0;
    private _activeCategory = 0;
    private _catalogs = <any>[];
    private _categories = <any>[];
    private _categoriesTemp = <any>[];
    private _searchCategory = <any>[];
    private _products = <any>[];
    private _searchProducts = <any>[];
    private _filters = {
        search: '',
        catalog: '',
        category: '',
    };

    filters$ = this.filterSource.asObservable();
    catalogs$ = this.catalogSource.asObservable();
    categories$ = this.categorySource.asObservable();
    products$ = this.productSource.asObservable();

    set filters(filters: any) {
        this._filters = {
            ...this._filters,
            ...filters,
        };
        if (filters.search) {
            this._activeCatalog = 0;
            this._activeCategory = 0;
            this.fetchAllCataloguesCategoriesAndProducts(filters.ownerId, filters.businessId)
        } else if (filters.category) {
            this._activeCategory = this._categories.findIndex((category: any) => { return category.id == filters.category; });
            if (this._activeCategory >= 0) {
                this.products = this._categories[this._activeCategory]?.servicesProducts;
            }
            else {
                this._activeCategory = 0;
                this.categories = this._categories;
                this.products = this._categories[this._activeCategory]?.servicesProducts;
            }
        } else {
            this._activeCatalog = this._catalogs.findIndex((catalog: any) => { return catalog.id == filters.catalog; });
            if (this._activeCatalog < 0) {
                this._activeCatalog = 0;
                this.catalogs = this._catalogs;
            }
            this._activeCategory = 0;
            if (this._catalogs[this._activeCatalog]) {
                this.categories = this._catalogs[this._activeCatalog]?.categories;
                this.products = this._categories[this._activeCategory]?.servicesProducts;
            }
        }
    }

    set catalogs(catalogs: any) {
        this._catalogs = catalogs;
        this.catalogSource.next(this._catalogs);
    }

    set categories(categories: any) {
        if (categories.findIndex((category: any) => { return category.id == 0; }) >= 0) {
            this._searchCategory = categories;
            this.categorySource.next(this._searchCategory);
            this._searchProducts = this._searchCategory[0]?.servicesProducts;
            this.productSource.next(this._searchProducts);
        }
        else {
            this._categories = categories;
            this.categorySource.next(this._categories);
            this.products = this._categories[this._activeCategory]?.servicesProducts;
        }
    }

    set products(products: any) {
        this._products = products;
        this.productSource.next(this._products);
    }

    get filters() {
        return this._filters;
    }

    get catalogs() {
        return this._catalogs;
    }

    get categories() {
        return this._categories;
    }

    get searchCategory() {
        return this._searchCategory;
    }

    get products() {
        if (this._filters?.search) {
            return this._categoriesTemp[0].servicesProducts;
        } else {
            return this._categories[this._activeCategory].servicesProducts;
        }
    }

    get searchProducts() {
        return this._searchProducts;
    }

    constructor(
        private httpService: HttpService,
        private endpoints: EndpointsService,
        private businessService: BusinessService,
    ) { }

    fetch(ownerId = '-1-', businessId = '-2-', filters?: any) {
        const isAppointent = this.businessService.business.appointment ? '/isappointment' : '';
        if (filters?.search) {
            return this.httpService.get(this.endpoints.products.replace('{ownerId}', ownerId).replace('{businessId}', businessId) + '/category/services-products/predictive/' + filters.search);
        }
        return this.httpService.get(this.endpoints.products.replace('{ownerId}', ownerId).replace('{businessId}', businessId) + isAppointent);
    }

    fetchAllCataloguesCategoriesAndProducts(ownerId = '', businessId = '') {
        if (this._filters?.search) {
            this.fetch(ownerId, businessId, this._filters).subscribe((res: any) => {
                const categories = this.searchCategory;
                const products = res.data;
                const index = categories.findIndex((category: any) => { return category.id == 0; });
                if (index >= 0) {
                    categories[index].servicesProducts = products;
                    this.categories = categories;
                }
                else {
                    categories.push({
                        id: 0,
                        name: 'RESULTADOS DE BÚSQUEDA',
                        servicesProducts: products,
                    });
                    this.categories = categories;
                    this._categoriesTemp = categories;
                }
            });
            return this.products$;
        }
        this.fetch(ownerId, businessId).subscribe((res: any) => {
            const catalogues = res.data;
            const cats = <any>[];
            catalogues?.forEach((catalogue: any) => {
                if (catalogue.active && catalogue.categories?.length > 0) {
                    catalogue.categories = catalogue.categories.filter((category: any) => { return (category.active && category.servicesProducts?.length > 0); });
                    catalogue.categories.forEach((category: any) => {
                        category.servicesProducts = category.servicesProducts.filter((product: any) => { return product.active; });
                    });
                    cats.push(catalogue);
                }
            });
            if (cats[this._activeCatalog]) {
                this.categories = cats[this._activeCatalog]?.categories;
            }
            this.catalogs = cats;
        });
        return this.products$;
    }

    clearCategory() {
        this.filters = { catalog: '', category: '' };
    }
}
