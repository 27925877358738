import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private _business: any

  get business() {
    return this._business;
  }

  set business(value) {
    this._business = value;
  }

  private theme$ = new BehaviorSubject<string>('');

  set theme(color: string) {
    this.theme$.next(color);
  }

  get themeObs(): Observable<string> {
    return this.theme$.asObservable();
  }

  constructor(
    private httpService: HttpService,
    private endpoints: EndpointsService,
  ) { }

  fetch(id: string) {
    this._business = this.httpService.get(this.endpoints.business + '/' + id);
    return this._business;
  }

  fetchById(id: string) {
    this._business = this.httpService.get(this.endpoints.businessId.replace('{businessId}', id));
    return this._business;
  }

  businessId!: string;

  obtenerIdbussines(): string {

    // Lógica para obtener el ID desde alguna fuente
    const businId = this.businessId;
    return businId;
  }


}
