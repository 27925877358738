import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Injectable, Injector } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getCountFromServer,
} from '@angular/fire/firestore';
import { ProfileService } from 'src/app/share/services/profile.service';
import { EventsService } from 'src/app/share/services/events.service';
import { typeEvent, dataset, pageTitle, dataCustom } from 'src/app/share/enum/enum';




interface ICretateEvents {
  user: string,
  titleScreen: string,
  timeScreen: string,
  event: string,
  tagDate: string,
  table: string,
  device: string,

  model?: IcreateModelEvent,
}


interface IcreateModelEvent {

  //General
  locationPage?: string,
  owner?: string,
  dob?: string,
  gender?: string,
  phone?: string,
  razonSocial?: string,
  rfc?: string,
  regimenFiscal?: string,
  usoCfdi?: string,
  email?: string,



  // Address
  exteriorNumber?: string,
  interiorNumber?: string,
  zip?: string,
  state?: string,
  county?: string,
  neighborhood?: string,
  addressList?: string,


  //profile
  // dob?: string,
  // email?: string,
  firstFamilyName?: string,
  // gender?: string,
  name?: string,
  //  phone?: string,


  //billing
  billingRazonSocial?: string,
  billingRfc?: string,
  billingRegimenFiscal?: string,
  //uso_cfdi?: string,
  address?: string,
  //interiorNumber?: string,
  billingEmail?: string,

  //business
  //owner?: string,
  businessType?: string,
  //name?: string,
  description?: string,
  //phone?: string,
  postalCode?: string,
  federalEntity?: string,
  municipality?: string,
  cologne?: string,
  street?: string,
  outdoorNumber?: string,
  // interiorNumber?: string,
  location?: string,
  instagram?: string,
  facebook?: string,
  webPage?: string,
  businessApplication?: string,
  active?: string,
  color?: string,


}


interface ICreateSalesEvents {
  user: string,
  business?: string,
  titleScreen: string,
  timeScreen: string,
  event: string,
  tagDate: string,
  table: string,
  device: string,
  locationPage?: string,
  model?: ICreateSalesModelEvent,




}


interface ICreateSalesModelEvent {

  //Sales
  ownerId?: string,
  bussinesId?: string,
  appointmentId?: string,
  customerName?: string,
  mail?: string,
  total?: Number,
  subtotal?: Number,
  taxes?: Number,
  discount?: Number,
  discountCode?: Number,
  salesDateTime?: string,
  cardId?: string,
  status?: string,
  servicesProducts?: string,
  payment?: string,
  paymentType?: string,


}


@Injectable({
  providedIn: 'root',
})





export class Util {

  createSalesModelEvent !: ICreateSalesEvents;
  createSalesModel!: ICreateSalesModelEvent;
  createEvent !: ICretateEvents;
  createModel!: IcreateModelEvent;
  deviceInfo: any;

  constructor(

    private deviceDetectorService: DeviceDetectorService,
    private analytics: AngularFireAnalytics,
    private firestore: Firestore,
    private profileService: ProfileService,
    private eventsService: EventsService,
  ) {


  }


  async eventTagAnalytics(dataCustom: any, screenTitle: string, eventType: string, datasetName: string) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
   
    const firestore = this.firestore
    





    this.createModel = {

    }

    if (datasetName === dataset.address) {
     






      this.createModel = {

        locationPage: screenTitle,
        email: this.profileService.email,
        owner: this.profileService.name + " " + this.profileService.first_family_name,
        dob: this.profileService.dob,
        gender: this.profileService.gender,
        phone: this.profileService.phone,
        //  razon_social: this.profileService.billing_razon_social,
        rfc: this.profileService.billing_rfc,
        regimenFiscal: this.profileService.billing_regimen_fiscal,
        usoCfdi: this.profileService.billing_uso_cfdi,


        exteriorNumber: dataCustom.exterior_number,
        interiorNumber: dataCustom.interior_number_number,
        zip: dataCustom.zip,
        state: dataCustom.state,
        county: dataCustom.county,
        neighborhood: dataCustom.neighborhood,
        addressList: '[' + ']',

      }

     
    } else if (datasetName === dataset.profile) {
     






      this.createModel = {
        locationPage: screenTitle,
        // email: this.profileService.billing_email,
        owner: this.profileService.name + " " + this.profileService.first_family_name,
        // dob: this.profileService.dob,
        //  gender: this.profileService.gender,
        //  phone: this.profileService.phone,
        //  razon_social: this.profileService.billing_razon_social,
        rfc: this.profileService.billing_rfc,
        regimenFiscal: this.profileService.billing_regimen_fiscal,
        usoCfdi: this.profileService.billing_uso_cfdi,

        dob: dataCustom.dob,
        email: dataCustom.email,
        firstFamilyName: dataCustom.first_family_name,
        gender: dataCustom.gender,
        name: dataCustom.name,
        phone: dataCustom.phone,

      }

    
    } else if (datasetName === dataset.billing) {
    






      this.createModel = {
        locationPage: screenTitle,
        email: this.profileService.email,
        owner: this.profileService.name + " " + this.profileService.first_family_name,
        dob: this.profileService.dob,
        gender: this.profileService.gender,
        // phone: this.profileService.phone,
        //  razon_social: this.profileService.billing_razon_social,
        rfc: this.profileService.billing_rfc,
        regimenFiscal: this.profileService.billing_regimen_fiscal,
        //uso_cfdi: this.profileService.billing_uso_cfdi,


        billingRazonSocial: dataCustom.razon_social,
        billingRfc: dataCustom.rfc,
        billingRegimenFiscal: dataCustom.regimen_fiscal,
        usoCfdi: dataCustom.uso_cfdi,
        billingEmail: dataCustom.email,
        phone: dataCustom.phone,
        address: dataCustom.address1,
        exteriorNumber: dataCustom.exterior_number,
        interiorNumber: dataCustom.interior_number,
        zip: dataCustom.zip,
        state: dataCustom.state,
        county: dataCustom.county,
        neighborhood: dataCustom.neighborhood,

      }

     
    } else if (datasetName === dataset.login) {
     

      this.createModel = {
        locationPage: screenTitle
      }
    } else if (datasetName === dataset.notifications) {


      this.createModel = {
        locationPage: screenTitle
      }
    } else if (datasetName === dataset.business) {
      





      if (eventType === typeEvent.negocio) {
        this.createModel = {
          //  locationPage: screenTitle,
          owner: dataCustom.ownerId,
          businessType: dataCustom.businessTypeId,
          name: dataCustom.name,
          description: dataCustom.description,
          phone: dataCustom.phone,
          postalCode: dataCustom.postalCode,
          federalEntity: dataCustom.federalEntity,
          municipality: dataCustom.municipality,
          cologne: dataCustom.cologne,
          street: dataCustom.street,
          outdoorNumber: dataCustom.outdoorNumber,
          interiorNumber: dataCustom.interiorNumber,
          location: dataCustom.location,
          instagram: dataCustom.instagram,
          facebook: dataCustom.facebook,
          webPage: dataCustom.webPage,
          businessApplication: dataCustom.businessApplication,
          active: dataCustom.active,
          color: dataCustom.color,

        }

      } else if (eventType === typeEvent.selCategory) {
        this.createModel = {
          //  locationPage: screenTitle,

          businessType: dataCustom


        }

      }

     
    }


    this.createEvent = {
      user: this.profileService.email,
      titleScreen: screenTitle,
      event: eventType,
      timeScreen: '',
      tagDate: this.getDate(),
      table: datasetName,
      device: this.deviceInfo.os,


      model: this.createModel,


    }





    this.analytics.logEvent(screenTitle + 'EventsAppClient',
      {
        'firsttimeuser': true,
        'Name': 'AppCliente',
        'email': this.profileService.billing_email,
        'screenTitle': screenTitle,
        'event': eventType,
        'table': datasetName,
        'device': this.deviceInfo.os,
        'owner': this.profileService.name + " " + this.profileService.first_family_name,
        'dob': this.profileService.dob,
        'gender': this.profileService.gender,
        'phone': this.profileService.phone,
        'rfc': this.profileService.billing_rfc,
        'regimen_fiscal': this.profileService.billing_regimen_fiscal,
        'uso_cfdi': this.profileService.billing_uso_cfdi,
        
      });


    const appointmentAvailability = await this.eventsService.sendingEventsApp(this.createEvent);


  }


  async eventSalesTagAnalytics(dataCustom: any, productsList: any, screenTitle: string, eventType: string, datasetName: string) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();

    const firestore = this.firestore
    




    let serviceProductList = '';
    if (dataCustom.servicesProducts) {

      serviceProductList = dataCustom.servicesProducts.toString();


    }


    

    let productsCart: string[] = [];

    for (let listProducts of productsList) {
      if (listProducts.products) {
        for (let product of listProducts.products) {
         
          productsCart.push(product.name + ':' + product.quantity);
        }
      }
     

    }

    let tipoTarjeta = '';

    
    
    if(dataCustom.payment.isTarjetaVirtual){
      tipoTarjeta = 'Pago con tarjeta virtual';
    }else{
      tipoTarjeta = 'Pago con tarjeta bancaria';
    }



    serviceProductList = productsCart.toString();
    

    this.createSalesModel = {
      ownerId: this.profileService.email,
      bussinesId: productsList[0].name,

      appointmentId: dataCustom.appointmentId,
      customerName: dataCustom.customerName,
      mail: dataCustom.mail,
      total: Number(dataCustom.total),
      subtotal: Number(dataCustom.subtotal),
      taxes: 0,
      discount: 0,
      discountCode: 0,
      salesDateTime: dataCustom.salesDateTime,
      cardId: dataCustom.cardId,
      status: 'active',
      servicesProducts: '[' + serviceProductList + ']',
      payment: tipoTarjeta,
      paymentType: eventType,

    }


    this.createSalesModelEvent = {
      user: this.profileService.email,
      locationPage: screenTitle,

      titleScreen: screenTitle,
      timeScreen: '',
      event: eventType,
      tagDate: this.getDate(),
      table: datasetName,
      device: this.deviceInfo.os,
      model: this.createSalesModel,

    };

    



    this.analytics.logEvent(screenTitle + 'EventsAppClient',
      {
        'firsttimeuser': true,
        'Name': 'AppCliente',
        'email': this.profileService.billing_email,
        'screenTitle': screenTitle,
        'event': eventType,
        'table': datasetName,
        'device': this.deviceInfo.os,
        'owner': this.profileService.name + " " + this.profileService.first_family_name,
        'total': dataCustom.total,
        'productos': serviceProductList,
        
      });


    const appointmentAvailability = await this.eventsService.sendingEventsApp(this.createSalesModelEvent);


  }


  getDate() {
    const date = new Date();
    let today = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    let hourToday = date.toString().substring(16, 21);

    
    return today + ' ' + hourToday;
  }
}
