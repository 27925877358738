import { Injectable } from '@angular/core';

import {
    Firestore,
    setDoc,
    doc,
    getDoc,
    DocumentReference,
    updateDoc,
} from '@angular/fire/firestore';
import { th } from 'date-fns/locale';
import { ConfirmController } from '../controllers/confirm.controller';


interface IUserProfile {
    name: string;
    first_family_name: string;
    dob: string;
    gender: string;
    phone: string;
    agreement: boolean;
    agreement_date: Date;
    picture: string;
    email:string;
}

interface IUserBillingAddress{
    razon_social: boolean;
    rfc: string;
    regimen_fiscal: string;
    uso_cfdi: string;
    email: string;
    phone: string;
    address1: string;
    exterior_number: string;
    interior_number: string;
    zip: string;
    state: string;
    county: string;
    neighborhood: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    ready = false;
    userProfile!: IUserProfile;
    userBillingAddress!:IUserBillingAddress;
    userDocRef!: DocumentReference;
    
    constructor(
        private firestore: Firestore,
        private confirmController: ConfirmController
    ) { }

    get name() {
        return this.userProfile && this.userProfile.name || '';
    }

    get email() {
        return this.userProfile && this.userProfile.email || '';
    }
    get first_family_name() {
        return this.userProfile && this.userProfile.first_family_name || '';
    }
    get dob() {
        return this.userProfile && this.userProfile.dob || '';
    }
    get gender() {
        return this.userProfile && this.userProfile.gender || '';
    }
    get phone() {
        return this.userProfile && this.userProfile.phone || '';
    }
    get picture() {
        return this.userProfile && this.userProfile.picture || '';
    }

    get billing_razon_social() {
        return this.userBillingAddress && this.userBillingAddress.razon_social || '';
    }

    get billing_rfc() {
        return this.userBillingAddress && this.userBillingAddress.rfc || '';
    }
 
    get billing_regimen_fiscal() {
        return this.userBillingAddress && this.userBillingAddress.regimen_fiscal || '';
    }

    get billing_uso_cfdi() {
        return this.userBillingAddress && this.userBillingAddress.uso_cfdi || '';
    }

    get billing_email() {
        return this.userBillingAddress && this.userBillingAddress.email || '';
    }
    get billing_phone() {
        return this.userBillingAddress && this.userBillingAddress.phone || '';
    }
    get billing_address1() {
        return this.userBillingAddress && this.userBillingAddress.address1 || '';
    }
    get billing_exterior_number() {
        return this.userBillingAddress && this.userBillingAddress.exterior_number || '';
    }
    get billing_interior_number() {
        return this.userBillingAddress && this.userBillingAddress.interior_number || '';
    }
    get billing_zip() {
        return this.userBillingAddress && this.userBillingAddress.zip || '';
    }
    get billing_state() {
        return this.userBillingAddress && this.userBillingAddress.state || '';
    }
    get billing_county() {
        return this.userBillingAddress && this.userBillingAddress.county || '';
    }
    get billing_neighborhood() {
        return this.userBillingAddress && this.userBillingAddress.neighborhood || '';
    }

    async register(registrationId: string, registration: any) {
        try {
            const registrationData = {
                name: registration.name,
                first_family_name: registration.first_family_name,
                dob: registration.dob,
                agreement: registration.agreement,
                agreement_date: new Date(),
                email: registration.email,
                virtualCards: [registration.virtualCard]
            };

            await setDoc(doc(this.firestore, 'userProfile', registrationId), registrationData);

            return true;
        } catch(e) {
            console.log('Error registering profile: RP01', e);
        }

        return false;
    }

    async updateData(profile: any) {
        const firestore = this.firestore;
        const userProfile = this.userProfile;

        try {
            const data:any = {
                name: profile.name,
                first_family_name: profile.first_family_name,
                dob: profile.dob,
                gender: profile.gender,
                phone: profile.phone,
            }
            if(profile.email){
                // Add email to the object if it exists
                data['email'] = profile.email;
            }
            await updateDoc(doc(firestore, 'userProfile', this.userDocRef.id), data);

            // if Succesful database update, update local profile
            userProfile.name = profile.name;
            userProfile.first_family_name = profile.first_family_name;
            userProfile.dob = profile.dob;
            userProfile.gender = profile.gender;
            userProfile.phone = profile.phone;
            return true;
        } catch(e) {
            return false;
        }
    }

    async updateBillingAddress(userBillingAddress: any) {
        const firestore = this.firestore;

        try {
            await updateDoc(doc(firestore, 'userProfile', this.userDocRef.id), {
                billingAddress: userBillingAddress,
            });

            // Check if userBillingAddress exists
            if(!this.userBillingAddress){
                this.userBillingAddress = {} as IUserBillingAddress;
            }
            // if Succesful database update, update local data
            this.userBillingAddress.razon_social = userBillingAddress.razon_social;
            this.userBillingAddress.rfc = userBillingAddress.rfc;
            this.userBillingAddress.regimen_fiscal = userBillingAddress.regimen_fiscal;
            this.userBillingAddress.uso_cfdi = userBillingAddress.uso_cfdi;
            this.userBillingAddress.email = userBillingAddress.email;
            this.userBillingAddress.phone = userBillingAddress.phone;
            this.userBillingAddress.address1 = userBillingAddress.address1;
            this.userBillingAddress.exterior_number = userBillingAddress.exterior_number;
            this.userBillingAddress.interior_number = userBillingAddress.interior_number;
            this.userBillingAddress.zip = userBillingAddress.zip;
            this.userBillingAddress.state = userBillingAddress.state;
            this.userBillingAddress.county = userBillingAddress.county;
            this.userBillingAddress.neighborhood = userBillingAddress.neighborhood;

            return true;
        } catch(e) {
            return false;
        }
    }

    async login(registrationId: string) {
        const firestore = this.firestore;

        try {
            this.userDocRef = doc(firestore, "userProfile", registrationId)
            const profileDoc = await getDoc(this.userDocRef);
            if (profileDoc.exists()) {
                this.userProfile = {
                    name: profileDoc.get('name'),
                    first_family_name: profileDoc.get('first_family_name'),
                    dob: profileDoc.get('dob'),
                    gender: profileDoc.get('gender'),
                    phone: profileDoc.get('phone'),
                    agreement: profileDoc.get('agreement'),
                    agreement_date: profileDoc.get('agreement_date'),
                    picture: profileDoc.get('picture'),
                    email: profileDoc.get('email'),
                }

                this.userBillingAddress = profileDoc.get('billingAddress');
                this.ready = true;
            } else {
                return false;
            }
        } catch(e) {
            return false
        }
        return true;
    }

    logout() {
        this.ready = false;
        this.userProfile = {
            name: '',
            first_family_name: '',
            dob: '',
            gender: '',
            phone: '',
            agreement: false,
            agreement_date: new Date(0),
            picture: '',
            email: '',
        }
    }

    async updatePicture(pictureDataUrl: string) {
        try {
            await updateDoc(doc(this.firestore, 'userProfile', this.userDocRef.id), {
                picture: pictureDataUrl,
            });

            // if Succesful database update, update local profile
            this.userProfile.picture = pictureDataUrl;
            return true;
        } catch(e:any) {
            if(e.name === 'FirebaseError') {
                await this.confirmController.createAndPresent({
                    message: "Selecciona una imagen menor a 2MB",
                });
            }
            return false;
        }
    }

    async updateFCMToken(toke: string) {
        try {
            await updateDoc(doc(this.firestore, 'userProfile', this.userDocRef.id), {
                fcm: toke,
            });

            return true;
        } catch(e) {
            return false;
        }
    }
        
    async deletePicture() {
        try {
            await updateDoc(doc(this.firestore, 'userProfile', this.userDocRef.id), {
                picture: '',
            });

            this.userProfile.picture = '';
            return true;
        } catch(e) {
            return false;
        }

    }
}
