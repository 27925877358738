import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonMenu, LoadingController, NavController } from '@ionic/angular';
import { AuthService } from '../../share/services/auth.service';
import { ProfileService } from 'src/app/share/services/profile.service';
import { ConfirmController } from 'src/app/share/controllers/confirm.controller';
import { NotificationsService } from 'src/app/share/services/notifications.service';
import { environment } from 'src/environments/environment';
import { Util } from 'src/app/share/util/util';
import { typeEvent, dataset, pageTitle, dataCustom } from 'src/app/share/enum/enum';



@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

    @ViewChild('menu') menu?: IonMenu;
    get notificationsCount() {
        return this.notificationsService.count;
    }

    get name() {
        return this.profileService.name;
    }
    get picture() {
        return this.profileService.picture;
    }

    get appVersion() {
        return environment.mapache.version;
    }

    constructor(
        private loadingController: LoadingController,
        private authService: AuthService,
        private profileService: ProfileService,
        private router: Router,
        private confirmController: ConfirmController,
        private notificationsService: NotificationsService,
        private util: Util,
        private navCtrl: NavController,
    ) { }


    ngOnInit() { }

    async logout() {
        const loading = await this.loadingController.create();
        await loading.present();

        await this.profileService.logout();
        await this.authService.logout();

        await loading.dismiss();

        this.menu?.close(true);

        window.location.reload();

        // this.router.navigateByUrl('/', { replaceUrl: true });
        this.navCtrl.navigateForward('/', { animated: false, replaceUrl: true });
    }

    async confirmLogout() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optLogout, dataset.notifications);
        await this.confirmController.createAndPresent({
            icon: 'logout-icon',
            message: '¿Quieres cerrar esta sesión ahora?',
            confirm: {
                handler: async () => {
                    await this.logout();
                },
            },
            cancel: {
                hide: false,
            }
        });
    }

    async openProfile() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optProfile, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/profile', { replaceUrl: true });
            this.navCtrl.navigateForward('/profile', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openAddresses() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optAaddresses, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/addresses', { replaceUrl: true });
            this.navCtrl.navigateForward('/addresses', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openPaymentMethods() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optPayment, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/payment-methods', { replaceUrl: true });
            this.navCtrl.navigateForward('/payment-methods', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openBilling() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optBilling, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/billing', { replaceUrl: true });
            this.navCtrl.navigateForward('/billing', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openOrdersHistory() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optHistory, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/orders-history', { replaceUrl: true });
            this.navCtrl.navigateForward('/orders-history', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openNotifications() {
        this.util.eventTagAnalytics(dataCustom.empty, pageTitle.menu, typeEvent.optNotify, dataset.notifications);
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/notifications', { replaceUrl: true });
            this.navCtrl.navigateForward('/notifications', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openHelp() {
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/help', { replaceUrl: true });
            this.navCtrl.navigateForward('/help', { animated: false, replaceUrl: true });
        }, 700);
    }

    async openAviso() {
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('./privacy-policy', { replaceUrl: true });
            this.navCtrl.navigateForward('./privacy-policy', { animated: false, replaceUrl: true });
        }, 700);
    }

    async terminos() {
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('./terms-and-conditions', { replaceUrl: true });
            this.navCtrl.navigateForward('./terms-and-conditions', { animated: false, replaceUrl: true });
        }, 700);
    }

    lili() {
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('/evaluation', { replaceUrl: true });
            this.navCtrl.navigateForward('/evaluation', { animated: false, replaceUrl: true });
        }, 700);
    }

    modalStiker() {
        this.menu?.close(true);
        setTimeout(() => {
            // this.router.navigateByUrl('modal-stiker', { replaceUrl: true });
            this.navCtrl.navigateForward('modal-stiker', { animated: false, replaceUrl: true });
        }, 700);
    }
}
