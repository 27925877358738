import { Component } from '@angular/core';
import { AuthService } from './share/services/auth.service';
import { ProfileService } from './share/services/profile.service';
import { Observable } from 'rxjs';
import { QrReaderService } from './share/services/qr-reader.service';
import { HttpService } from './share/services/http.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    isScanRequired$: Observable<boolean>;

    constructor(
        private authService: AuthService,
        private profileService: ProfileService,
        private qrReaderService: QrReaderService,
        private httpService: HttpService,
    ) {
        if (!this.profileService.ready) {
            this.authService.onAuthStateChangedRegister(async (user) => {
                if (user) {
                    this.authService.user = user;
                    const bearerToken = await user.getIdTokenResult();
                    this.httpService.bearerToken = bearerToken.token;
                    await this.profileService.login(user.uid);
                }
            });
        }
        this.isScanRequired$ = this.qrReaderService.isScanRequiredObs
    }

    stopScan() {
        this.qrReaderService.isScanRequired = false
    }
}
