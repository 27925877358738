<ion-text
    fill="clear"
    id="terms-and-conditions-modal"
    class="button-inline"
    (click)="setOpen(true)"
    >Términos y condiciones</ion-text
>
<ion-modal
    [isOpen]="isModalOpen"
    (ionModalDidDismiss)="closeModalTerms()"
    class="center-modal policy-modal"
  
>
    <ng-template>    
        <ion-content class="ion-padding" style="background-image: none">
            <div class="enc_modal">
                <ion-button
                class="close-modal-button"
                fill="clear"
                (click)="setOpen(false)"
            >
                <ion-icon size="large" name="close-outline"></ion-icon>
            </ion-button>
            <app-logo class="logo-small"></app-logo>
            <h1 class="ion-text-center">
                Contrato de Términos y Condiciones de Uso de la Aplicación para
                usuarios de Baguu
            </h1>
            </div>
         
           <div  style="margin-top: 250px;">
            <p>
                Este contrato de términos y condiciones ("Contrato") establece
                los términos y condiciones bajo los cuales los usuarios
                ("Usuario" o "Usuarios") pueden acceder y utilizar la aplicación
                móvil llamada Baguu ("la Aplicación") desarrollada por ASG
                Grande Advisory Services S.A. de C.V. Al descargar, instalar o
                utilizar la Aplicación, el Usuario acepta estar sujeto a los
                términos y condiciones establecidos en este Contrato.
            </p>
            <ul>
                <li>
                    1. Descripción de la Aplicación
                </li>
            </ul>
            <p>La aplicación Baguu proporciona un directorio de pequeñas y medianas empresas (Pymes) enfocadas en servicios y productos, como cafeterías, salones de belleza, spa, salones de tatuajes, barberías, lavanderías, locales independientes, pequeñas franquicias, entre otros, mismas que se han unido a la Aplicación de forma voluntaria. La Aplicación permite a los Usuarios acceder a información relevante sobre las ubicaciones, horarios, catálogos de servicios y productos, fotos e información útil de los negocios registrados, así como comprar aquellos servicios y productos por medio de la Aplicación.</p>
            <ul>
                <li>
                    2.	Registro y cuenta de usuario
                </li>
            </ul>
            <p>2.1. Para utilizar todas las funcionalidades de la Aplicación, el Usuario deberá registrarse creando una cuenta personal. Al hacerlo, el Usuario se compromete a proporcionar información precisa, verídica y completa.</p>
            <p>2.2. El Usuario será responsable de mantener la confidencialidad de su cuenta y contraseña, y será el único responsable de todas las actividades realizadas en su cuenta.</p>
            <p>2.3. El Usuario acepta notificar de inmediato a la Empresa cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.</p>
            <ul>
                <li>
                    3.	Agendar Citas y Pagos
                </li>
            </ul>
            <p>3.1. La Aplicación permite a los Usuarios agendar citas con los negocios registrados. Cada Usuario podrá agendar una cita y el procedimiento de gestión, cambios o cancelación deberá llevarse a cabo directamente con el negocio con el cual se realizó la reservación.</p>
            <p>3.2. Para realizar pagos por los servicios adquiridos, Baguu acepta tarjetas bancarias (Visa y MasterCard) y tarjetas de prepago emitidas por la Aplicación. El Usuario entiende y acepta que la Baguu no cobra comisiones a los Usuarios por el uso de la Aplicación, pero sí cobra una comisión por cada transacción realizada. </p>
            <ul>
                <li>
                    4.	Política de devoluciones y promociones
                </li>
            </ul>
            <p>4.1. Baguu establece una política de no devoluciones en efectivo. Sin embargo, en caso de cancelación o inconveniente, el Usuario podrá recibir un saldo a favor dentro de la Aplicación para futuras compras o servicios. Este saldo a favor se cargará al Usuario dentro de los 15 días hábiles posteriores a la solicitud de devolución o cancelación. Este monto se determinará con base en la valoración y evaluación que haya realizado la Baguu en relación con el caso reportado.</p>
            <p>4.2. Baguu se reserva el derecho de ofrecer promociones y descuentos especiales a través de la Aplicación. Estas promociones estarán sujetas a términos y condiciones específicos, los cuales deberán ser aceptados por el Usuario al participar en ellas.</p>
            <ul>
                <li>
                    5.	Tarifas y Comisiones
                                </li>
            </ul>
            <p>5.1. Los negocios registrados en la Aplicación podrán establecer tarifas específicas por sus servicios a través del prorrateo de sus productos y servicios. Estas tarifas serán comunicadas claramente en la información proporcionada en la Aplicación y podrán estar sujetas a cambios por parte de los negocios.</p>
            <P>5.2. Baguu cobrará una comisión por las transacciones realizadas a través de la Aplicación, la cual será establecida y comunicada de forma verbal a los propietarios de los negocios registrados.</P>
            <ul>
                <li>
                    6.	Propiedad Intelectual
                                </li>
            </ul>
            <p>6.1. El Usuario reconoce y acepta que la Aplicación y todos sus contenidos, incluyendo, pero no limitado a logotipos, diseños, textos, gráficos, imágenes, software, procesos y demás materiales, son propiedad exclusiva de ASG Grande Advisory Services S.A. de C.V. y están protegidos por las leyes de propiedad intelectual aplicables.</p>
            <p>6.2. Queda estrictamente prohibido copiar, modificar, distribuir, transmitir, mostrar, reproducir, publicar, licenciar o crear trabajos derivados de la Aplicación o de su contenido sin el consentimiento previo y por escrito de ASG Grande Advisory Services S.A. de C.V.</p>
            <ul>
                <li>7.	Limitación de Responsabilidad</li>
               
            </ul>
            <p>7.1. La ASG se esforzará por mantener la Aplicación y su funcionamiento de manera continua y libre de errores. Sin embargo, el Usuario entiende y acepta que la Aplicación se proporciona "tal cual" y que la ASG no garantiza la disponibilidad ininterrumpida o libre de errores de la Aplicación.</p>
            <p>7.2. En ningún caso, ASG será responsable por daños directos, indirectos, incidentales, especiales o consecuentes, incluyendo pérdida de beneficios, ingresos, datos o cualquier otro tipo de pérdida, derivados del uso o imposibilidad de uso de la Aplicación.</p>
            <ul><li>
                8.	Modificaciones y Terminación
            </li></ul>
            <p>8.1. ASG Grande Advisory Services S.A. de C.V se reserva el derecho de modificar, actualizar o terminar la Aplicación o este Contrato en cualquier momento, sin previo aviso. El Usuario será responsable de revisar y aceptar periódicamente los términos y condiciones actualizados.</p>
            <p>8.2. ASG Grande Advisory Services S.A. de C.V se reserva el derecho de suspender o cancelar la cuenta de cualquier Usuario que incumpla con este Contrato o que realice un uso indebido de la Aplicación.</p>
            <ul>
                <li>9.	Ley Aplicable y Jurisdicción</li>
               
            </ul>
            <p>Este Contrato se regirá e interpretará de acuerdo con las leyes vigentes en México. Cualquier disputa que surja en relación con este Contrato estará sujeta a la jurisdicción exclusiva de los tribunales competentes en México.</p>
            <ul>
                <li>10.	Política de Privacidad y Protección de Datos</li>
               
            </ul>
            <p>
                10.1. La Empresa se compromete a proteger la privacidad y los datos personales de los Usuarios de acuerdo con las leyes y regulaciones aplicables. Para obtener más información sobre cómo se recopilan, utilizan y protegen los datos personales, revisar, leer y aceptar el Aviso de privacidad de Baguu.
            </p>
            <ul>
                <li>11.	Disposiciones Generales</li>
               
            </ul>
            <p>11.1. Este Contrato constituye el acuerdo completo entre el Usuario y la Baguu con respecto al uso de la Aplicación y reemplaza cualquier acuerdo anterior o contemporáneo, ya sea escrito u oral.</p>
            <p>11.2. Si alguna disposición de este Contrato se considera inválida, ilegal o no aplicable por cualquier motivo, dicha disposición se considerará separada del Contrato y no afectará la validez y aplicabilidad de las disposiciones restantes.</p>
            <p>11.3. Cualquier renuncia a una disposición de este Contrato por parte de la ASG Grande Advisory Services S.A. de C.V. no se considerará una renuncia continua o adicional a dicha disposición o a cualquier otra disposición del Contrato.</p>
            <ul>
                <li>12.	Ley Aplicable y Jurisdicción</li>
               
            </ul>
            <p>12.1. Este Contrato se regirá e interpretará de acuerdo con las leyes de México.</p>
            <p>12.2. Cualquier disputa que surja en relación con este Contrato se resolverá mediante medios amistosos y de buena fe entre las partes. Si las partes no pueden resolver la disputa mediante medios amistosos, la disputa se resolverá mediante arbitraje de acuerdo con las reglas del Centro de Arbitraje y Mediación de México. El laudo arbitral será vinculante y ejecutable en cualquier tribunal competente.</p>
            <p>12.3. Si el Usuario tiene su domicilio en un país que no sea México, el Usuario se somete a la jurisdicción exclusiva de los tribunales de la Ciudad de México, renunciando a cualquier otra jurisdicción que pudiera corresponderle por razón de su domicilio presente o futuro, o por cualquier otra causa.</p>
            <ul>
                <li>13.	Cambios al Contrato</li>
               
            </ul>
            <p>13.1. ASG Grande Advisory Services S.A. de C.V. se reserva el derecho de actualizar o modificar este Contrato en cualquier momento y a su sola discreción, y se considerará que el Usuario ha aceptado dichos cambios al continuar usando la Aplicación.</p>
            <p>13.2. Si el Usuario no está de acuerdo con los cambios realizados al Contrato, el Usuario deberá dejar de utilizar la Aplicación.</p>
            <ul>
                <li>14.	Contacto</li> 
            </ul>
            <p>14.1. Si el Usuario tiene alguna pregunta o comentario sobre este Contrato, la Aplicación o ASG Grande Advisory Services S.A. de C.V., puede ponerse en contacto con la Empresa a través del correo electrónico info@baguu.mx</p>
            <p>Este es el contrato de términos y condiciones de la Aplicación Baguu, desarrollada por la empresa ASG Grande Advisory Services S.A. de C.V. Al aceptar estos términos y condiciones, el Usuario acepta todas las políticas, términos y condiciones establecidos en este Contrato y se compromete a cumplir con ellos en su totalidad.</p>
</div>
                   </ion-content>
    </ng-template>
</ion-modal>

