import { Component, OnInit, ViewChild } from '@angular/core';
import type { IonInput } from '@ionic/angular';

import { BusinessesService } from '../../services/businesses.service';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
    @ViewChild('ionInputSearchBar', { static: true }) ionInpionInputSearchBarutEl!: IonInput;
    
    constructor(
        private businessesService: BusinessesService,
    ) { }
    
    ngOnInit() {}

    onInput(ev:any) {
        this.businessesService.page = 0;
        const value = ev.target!.value;
        this.businessesService.filters = {
            search: value,
            page: 0,
        };
      }
    
}
