import { Injectable } from '@angular/core';
import {
    Firestore,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    updateDoc,
    getCountFromServer,
 } from '@angular/fire/firestore';

export interface IPaymentMethodItem {
    uid: string;
    active: boolean;
    name: string;
    number: string;
}

interface IPaymentMethod {
    active: boolean;
    type: string;
    name: string;
    number: string;
    number_masked: string;
    expiration_month: string;
    expiration_year: string;
    cvv: string;
}

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsService {
    currentPaymentItemUid = '';
    currentPaymentMethod!: IPaymentMethod;
    
    constructor(
        private firestore: Firestore,
    ) {
        this.clearCurrentPaymentMethod();
    }

    clearCurrentPaymentMethod() {
        this.currentPaymentMethod = {
            active: false,
            type: '',
            name: '',
            number: '',
            number_masked: '',
            expiration_month: '',
            expiration_year: '',
            cvv: '',
        }
        this.currentPaymentItemUid = '';
    }
    
    async register(userRegistrationId:string, registration: any) {
        const firestore = this.firestore;
        
        this.currentPaymentMethod = {
            active: registration.active,
            type: registration.type,
            name: registration.name,
            number: registration.number,
            number_masked: registration.number.slice(-4),
            expiration_month: registration.expiration_month,
            expiration_year: registration.expiration_year,
            cvv: registration.cvv,
        }
        
        try {
            if (this.currentPaymentItemUid) {
                // Fix 256 from trello
                // Passing this.currentPaymentMethod to updateDoc throws an error
                // Fix with {... <InterfaceName>} -> https://github.com/firebase/firebase-js-sdk/issues/5853
                await updateDoc(doc(firestore, `userProfile/${userRegistrationId}/paymentMethods`, this.currentPaymentItemUid), { ...this.currentPaymentMethod });
                // Set active item and ensure it is the only active item.
                if (this.currentPaymentMethod.active){
                    await this.setActiveItem(userRegistrationId, this.currentPaymentItemUid);
                }
            } else {
                const docRef = await addDoc(collection(firestore, `userProfile/${userRegistrationId}/paymentMethods`), this.currentPaymentMethod);
                // Set active item and ensure it is the only active item.
                if (registration.active){
                    await this.setActiveItem(userRegistrationId, docRef.id);
                }
            }
            return true;
        } catch(e) {
            console.log('Error saving payment method: PM01. ' + e);
            return false;
        }
    }

    async list(userRegistrationId:string) {
        try {
            const paymentMethodsRead =  await getDocs(collection(this.firestore, `userProfile/${userRegistrationId}/paymentMethods`))
            let paymentMethods = new Array<IPaymentMethodItem>();
            paymentMethodsRead.forEach(paymentMethodDoc => {
                if (paymentMethodDoc.exists()) {
                    paymentMethods.push({
                        uid: paymentMethodDoc.id,
                        active: paymentMethodDoc.get('active'),
                        name: paymentMethodDoc.get('name'),
                        number: paymentMethodDoc.get('number_masked'),
                    });
                }
            });
            return paymentMethods;
        } catch(e) {
            console.log("Error reading payment methods: PM01. " + e)
            return [];
        }
    }

    async fetchPaymentMethodToEdit(userRegistrationUid: string, paymentMethodUid: string) {
        try {
            const paymentMethodDoc = await getDoc(doc(this.firestore, `userProfile/${userRegistrationUid}/paymentMethods`, paymentMethodUid));
            if (paymentMethodDoc.exists()) {
                this.currentPaymentMethod = {
                    active: paymentMethodDoc.get('active'),
                    type: paymentMethodDoc.get('type'),
                    name: paymentMethodDoc.get('name'),
                    number: paymentMethodDoc.get('number'),
                    number_masked: paymentMethodDoc.get('number_masked'),
                    expiration_month: paymentMethodDoc.get('expiration_month'),
                    expiration_year: paymentMethodDoc.get('expiration_year'),
                    cvv: paymentMethodDoc.get('cvv'),
                }

                this.currentPaymentItemUid = paymentMethodDoc.id;
                return true
            }
        } catch(e) {
            console.log('Error reading payment method: PM02. ' + e)
        }

        return false;
    }

    async deletePaymentMethod(userRegistrationUid: string, paymentMethodUid: string) {
        try {
            await deleteDoc(doc(this.firestore, `userProfile/${userRegistrationUid}/paymentMethods`, paymentMethodUid));
            return true;
        } catch(e) {
            console.log('Error deleting payment method: PM03. ' + e);
        }
        return false;
    }

    async getPaymentMethodsCount(userRegistrationId:string){
        const coll = collection(this.firestore, `userProfile/${userRegistrationId}/paymentMethods`);
        const snapshot = await getCountFromServer(coll);
        return (snapshot.data().count);
    }

    async setActiveItem(userRegistrationId:string, currentPaymentItemUid:string) {
        const firestore = this.firestore;
        const docRefPath =  `userProfile/${userRegistrationId}/paymentMethods`;

        try {
            const paymentMethodsRead =  await getDocs(collection(this.firestore, docRefPath))
            paymentMethodsRead.forEach(paymentMethodDoc => {
                if (paymentMethodDoc.exists()) {
                    if (currentPaymentItemUid == paymentMethodDoc.id){
                        updateDoc(doc(firestore,docRefPath, paymentMethodDoc.id), {active: true});
                    } else {
                        updateDoc(doc(firestore, docRefPath, paymentMethodDoc.id), {active: false});
                    }
                }
            });
            return true;

        }catch(e){
            console.log("Error Setting active payment methods: PM02. " + e)
            return false;
        }
    }
}
