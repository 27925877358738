import { Injectable } from '@angular/core';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getCountFromServer,
} from '@angular/fire/firestore';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Observable, firstValueFrom } from 'rxjs';
//import { Analytics } from '@angular/fire/analytics/analytics';


export interface IAddressItem {
  uid: string;
  position: string;
  active: boolean;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
};

interface IAddress {
  active: boolean;
  position: string;
  address1: string;
  exterior_number: string;
  interior_number: string;
  zip: string;
  state: string;
  county: string;
  neighborhood: string;
};



@Injectable({
  providedIn: 'root'
})
export class EventsService {


  deviceInfo: any;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private firestore: Firestore,
    private httpService: HttpService,
    private endpoints: EndpointsService,
    private analytics: AngularFireAnalytics,
  ) { }





  async sendingEventsApp(dataCustom: any) {
    
    const response: any = await firstValueFrom(this.httpService.post(this.endpoints.tag, dataCustom));

  }


}
