<ion-text id="privacy-policy-modal" class="button-inline" (click)="setOpen(true)">

    Aviso de privacidad</ion-text>
<ion-modal [isOpen]="isModalOpen" (ionModalDidDismiss)="closeModalPrivacy()"
 class="center-modal policy-modal">
    <ng-template>
        <ion-content class="ion-padding" style="background-image: none;"> 
    
            <div class="enc_modal">
        <ion-button class="close-modal-button" fill="clear" (click)="setOpen(false)">
            <ion-icon size="large" name="close-outline"></ion-icon>
        </ion-button>
        <app-logo class="logo-small"></app-logo>
        <h1 class="ion-text-center">Aviso de privacidad</h1>
     </div>
            
      
          
                <div style="margin-top: 200px;">
                    <h5 class="text_H5">I. Introducción</h5><br>
                    <p class="aviso">En este aviso se describen los datos personales (“datos”) que recopilamos, cómo los usamos y compartimos, y sus opciones con respecto a ellos, por lo que te recomendamos leer este aviso con detenimiento.</p><br>
                    <h5 class="text_H5">II. Filosofía de trabajo</h5>
                    <p class="aviso">A. Alcance</p>
                    <p class="aviso">Este aviso se aplica a todos los usuarios de Baguu en todo México, incluidos los que usan la app, sitios web, funciones u otros servicios. </p>
                    <p class="aviso">
                        En este aviso se describe cómo Baguu y sus filiales recopilan y usan datos. Este aviso se aplica a todos los usuarios de Baguu en todo México, a menos que usen un servicio que otro aviso de privacidad cubra. Se aplica específicamente a las siguientes
                        partes:
                    </p>
                    <p class="aviso">Usuarios: Personas que consultan y compran productos y/o servicios relacionados a través de su cuenta en Baguu. </p>
                    <p class="aviso"> Partners de la App (negocios): Personas que ofrecen y brindan servicios o productos a los usuarios de Baguu.</p>
                    <p class="aviso"> Este aviso también rige otras recopilaciones de datos de Baguu con relación a sus servicios. Por ejemplo, podemos recopilar la información de contacto de los propietarios o empleados de los negocios y establecimientos registrados y
                        que los administran desde la aplicación. O bien los datos de las personas que inician sus solicitudes para ser Partners de la App, pero no las completan.</p>
                    <p class="aviso"> Se hará referencia a todas las personas sujetas a este aviso como “usuarios”. </p>
                    <p class="aviso"> Nuestras prácticas de privacidad están sujetas a las leyes vigentes en Ciudad de México, México.</p>

                    <p class="aviso"> Ingrese <a class="linkD" href="https://www.baguu.mx/privacidadapp/" target="_blank">aquí</a> para obtener información sobre las prácticas de privacidad de Baguu previstas en la ley de protección de datos personales de México (Ley Federal
                        de Protección de Datos Personales en Posesión de los Particulares).</p>
                    <p class="aviso"> Contáctenos aquí o a de las direcciones a continuación si tiene preguntas sobre nuestras prácticas: <a class="link" href="mailto:info@baguu.mx"> info@baguu.mx</a> o <a class="link" href="mailto:ventas@baguu.mx">ventas@baguu.mx</a></p>

                    <p class="aviso">B. Transferencia y responsable del tratamiento de datos</p>
                    <p class="aviso">ASG Grande Advisory Services S.A. de C.V. y Baguu son los responsables del tratamiento de los datos recopilados con relación al uso de los servicios de Baguu en la Ciudad de México, México.</p>
                    <p class="aviso">ASG Grande Advisory Services S.A. de C.V. es el responsable del tratamiento de los datos recopilados con relación al uso de los servicios de Baguu en cualquier otro lugar.</p>
                    <p class="aviso">ASG Grande Advisory Services S.A. de C.V. opera y procesa datos personales en todo México. Respetamos sus derechos de protección de datos, ya sea que se encuentre en su país de origen o en otro lugar.</p>
                    <p class="aviso">Baguu solo divulga datos personales a las fuerzas del orden público de acuerdo con los requisitos reglamentarios de las leyes vigentes, como el RGPD. Puede encontrar más información sobre las solicitudes de las fuerzas del orden público
                        <a class="linkD" href="https://www.baguu.mx/privacidadapp/" target="_blank">aquí.</a>
                    </p><br>
                    <p class="aviso">Para brindar una protección de datos equivalente donde sea que use nuestros servicios, Baguu implementó las siguientes medidas:
                    </p>
                    <p class="aviso">Políticas y procedimientos que limitan el acceso y procesamiento de datos personales para fines específicos.
                    </p>
                    <p class="aviso">Capacitación específica del personal responsable del tratamiento de los datos personales.
                    </p>
                    <p class="aviso">Una política de acceso a los datos por parte del Gobierno que rechaza el acceso del Gobierno a estos, excepto cuando la divulgación sea obligatoria según las leyes vigentes, incluido el RGPD de la UE, cuando exista un riesgo inminente
                        de daño grave a las personas o cuando se obtenga consentimiento.
                    </p>
                    <p class="aviso">Protección sólida de los datos mientras se transfieren entre la app de Baguu y nuestros servidores, y cuando los datos se procesan en nuestros servidores. Esto incluye el cifrado y uso de datos seudonimizados para proteger la identidad
                        completa de los usuarios frente a la divulgación no autorizada.
                    </p>
                    <p class="aviso">Para su comodidad, el ejercicio de sus derechos de protección de datos puede dirigirse a Baguu a través de cualquiera de los canales designados mencionados anteriormente y se gestionará de manera centralizada en nombre del responsable
                        del tratamiento correspondiente. </p><br>
                    <h5 class="text_H5">III. Recopilación y usos de los datos</h5><br>
                    <p class="aviso">A. Datos recopilados</p>
                    <p class="aviso">Baguu recopila los siguientes datos:</p> <br>
                    <p class="aviso">Datos que los usuarios proporcionan a Baguu, como los que brindan cuando crean sus cuentas.</p>
                    <p class="aviso">Datos que se generan cuando se utilizan nuestros servicios, como los detalles del dispositivo, ubicación y uso de la app.</p>
                    <p class="aviso">Datos de otras fuentes, como otros usuarios o propietarios de cuentas, Partners comerciales, proveedores generales, de seguros o de soluciones financieras, y autoridades gubernamentales.</p>
                    <p class="aviso">Para su conveniencia, proporcionamos un resumen visual de los datos que recopilamos y la manera en que los usamos aquí.</p><br>
                    <p class="aviso">Baguu recopila los siguientes datos: </p> <br>

                    <p class="aviso">1. Datos proporcionados por usuarios. Se incluye lo siguiente: </p> <br>

                    <p class="aviso">Información del perfil de usuario: Recopilamos datos cuando los usuarios crean o actualizan sus cuentas Baguu o cuando hacen pedidos a través de las funciones de pago, lo que puede incluir su nombre, correo electrónico, número de teléfono,
                        nombre de usuario y contraseña, dirección, foto de perfil.</p>
                    <p class="aviso">Datos demográficos: Podemos recopilar datos demográficos sobre los usuarios, como la fecha de nacimiento, la edad y el género, cuando sea necesario para ciertos servicios o programas de Baguu, por ejemplo, Saldo Baguu o funciones de
                        seguridad. También podemos recopilar datos demográficos, como el grupo etario y la composición del hogar, a través de encuestas de usuarios, y usar esta información para ofrecer productos y servicios de Baguu que puedan ser de
                        su interés.</p>
                    <br>
                    <p class="aviso">También podemos deducir datos demográficos a partir de otra información que recopilamos de los usuarios. Por ejemplo, podemos deducir el género de un usuario según su nombre para activar funciones de seguridad o para mostrar anuncios
                        personalizados de productos o servicios de terceros. </p> <br>
                    <p class="aviso">Contenido del usuario: Recopilamos los datos que los usuarios envían cuando se contactan con el soporte al cliente de Baguu (incluso en los Centros de atención o mediante funciones de chat telefónico o videoconferencia), brindan calificaciones
                        o comentarios a otros usuarios, negocios o establecimientos, o se comunican con Baguu de alguna otra manera. Esto puede incluir fotografías o grabaciones de audio o de video enviadas por los usuarios en relación con una solicitud
                        de soporte al cliente. También se incluyen los metadatos relacionados con el método que se usa para contactar a Baguu. El contenido de las fotos y grabaciones que envían los usuarios está sujeto a las Guías comunitarias de Baguu.
                    </p> <br>
                    <p class="aviso">2. Datos creados durante el uso de nuestros servicios. Se incluye lo siguiente: </p> <br>
                    <p class="aviso">Los usuarios y destinatarios de pedidos deberán ingresar manualmente la dirección para encontrar información de productos y servicios cercanos a él.</p>
                    <p class="aviso">Información de transacciones: Recopilamos información sobre las transacciones relacionadas con el uso de nuestros servicios, incluido el tipo de servicios solicitados o proporcionados como fecha y hora, artículos y/o servicios pedidos)
                        y la información de la transacción de pago (como el nombre y la ubicación del establecimiento, el monto cobrado y el método de pago). </p>
                    <p class="aviso">Datos de uso: Recopilamos información sobre cómo los usuarios interactúan con nuestros servicios. Esto incluye la fecha y hora de acceso, las funciones de la app o páginas vistas, el tipo de navegador, los fallos de la app y otras
                        actividades del sistema.</p>
                    <p class="aviso">Datos del dispositivo: Recopilamos datos sobre los dispositivos usados para acceder a nuestros servicios, lo que incluye modelos de hardware, dirección IP u otros identificadores únicos del dispositivo, sistemas operativos y versiones,
                        software, identificadores de publicidad, información de movimiento del dispositivo y datos de la red móvil. Aplica únicamente para dispositivos Android y iOS.
                    </p>
                    <p class="aviso">Datos de comunicación: Recopilamos datos sobre las comunicaciones por teléfono, mensajes de texto o en la app entre los usuarios que se activan a través de Baguu. Esto incluye la fecha y la hora, así como el contenido de los mensajes
                        de texto o en la app. </p> <br>
                    <p class="aviso">3. Datos de otras fuentes. Entre estos se incluyen los siguientes:</p> <br>
                    <p class="aviso">Propietarios de cuentas Baguu que solicitan servicios para otros usuarios o en nombre de ellos (como amigos o familiares) o bien que permiten que otros usuarios soliciten o reciban servicios a través de sus cuentas de negocios.
                    </p>
                    <p class="aviso">Usuarios u otras personas que proporcionan datos con relación a reclamos o disputas.</p>
                    <p class="aviso">Partners comerciales de Baguu a través de los cuales los usuarios crean o acceden a su cuenta Baguu, como proveedores de pago, servicios de redes sociales, apps o sitios web que utilicen las API de Baguu o cuyas API usa Baguu.</p>
                    <p class="aviso">Partners comerciales de Baguu relacionados con tarjetas de débito o de crédito emitidas por una institución financiera asociada con Baguu, en la medida en que se indique en los Términos y condiciones de la tarjeta.</p>
                    <p class="aviso">Proveedores que nos ayudan a verificar la identidad de los usuarios, información de antecedentes y cumplimiento de los requisitos para generar ganancias, o que revisan las cuentas de los usuarios en relación con sanciones, lavado de
                        dinero o exigencias relativas a la identificación del cliente. </p>
                    <p class="aviso">Fuentes disponibles públicamente.
                    </p>
                    <p class="aviso">Proveedores de servicios de marketing o distribuidores de datos, cuya información Baguu usa con fines publicitarios o de investigación.</p>
                    <p class="aviso">Funcionarios de salud pública o de la fuerza del orden público y otras autoridades gubernamentales.
                    </p> <br>
                    <p class="aviso">B. Cómo usamos los datos personales</p> <br>
                    <p class="aviso">Baguu usa los datos para que los productos y servicios, sean confiables y convenientes para los usuarios de la app. También usamos estos datos para lo siguiente:
                    </p> <br>
                    <p class="aviso">Mejorar la seguridad de los usuarios y de los servicios
                    </p>
                    <p class="aviso">Brindar soporte al cliente
                    </p>
                    <p class="aviso">Realizar tareas de investigación y de desarrollo
                    </p>
                    <p class="aviso">Permitir la comunicación entre usuarios
                    </p>
                    <p class="aviso">Hacer campañas de marketing y publicidad
                    </p>
                    <p class="aviso">Enviar comunicaciones no comerciales a los usuarios
                    </p>
                    <p class="aviso">Abordar temas relacionados con procedimientos legales
                    </p>
                    <p class="aviso">Para su conveniencia, proporcionamos un resumen visual de los datos que recopilamos y la manera en que los usamos aquí. </p>
                    <p class="aviso">Usamos los datos recopilados para realizar lo siguiente:
                    </p> <br>
                    <p class="aviso"> 1. Prestación de nuestros servicios. Baguu usa esta información para prestar, personalizar, mantener y mejorar los servicios.
                    </p> <br>
                    <p class="aviso">Esto incluye el uso de los datos para lo siguiente:</p>
                    <p class="aviso">Crear o actualizar cuentas.</p><br>
                    <p class="aviso">Permitir la oferta de productos o servicios de entrega, por ejemplo:</p>
                    <p class="aviso">Mostrar negocios de la App disponibles a los usuarios que requieren de servicios y/o productos. A los usuarios se les puede mostrar una lista de negocios acorde a su disponibilidad, ubicación o proximidad, configuración o preferencias
                        de los usuarios y otros factores.
                    </p><br>
                    <p class="aviso">Procesar pagos.
                    </p>
                    <p class="aviso">Personalizar las cuentas de los usuarios. Por ejemplo, podemos ofrecerle recomendaciones de lugares y servicios personalizados a destinatarios de pedidos según sus pedidos anteriores.
                    </p>
                    <p class="aviso">Proporcionar a los usuarios actualizaciones de entregas, generar recibos e informarles sobre los cambios en nuestros términos, servicios o políticas.</p>
                    <p class="aviso">Realizar las operaciones necesarias para mantener nuestros servicios, incluso para solucionar errores de software y problemas operativos.
                    </p><br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para cumplir con los términos de nuestros acuerdos con los usuarios, porque son compatibles con dichos usos o porque son relevantes para los intereses legítimos de Baguu
                        y de sus usuarios.</p> <br>
                    <p class="aviso">2. Seguridad. Usamos los datos para mantener la seguridad y la integridad de nuestros servicios y usuarios. Se incluye lo siguiente:
                    </p><br>
                    <p class="aviso">Verificar la identidad y el cumplimiento de los requisitos de los usuarios para solicitar servicios y entregas de productos, incluso a través de la verificación de existencia del establecimiento, a fin de evitar que Partners de la
                        App o usuarios poco confiables usen nuestros servicios.</p>
                    <p class="aviso">Además, podemos usar tecnología de reconocimiento facial para procesar fotografías de perfil de usuario, fotografías de identificación u otras fotografías enviadas por los usuarios a fin de evitar el robo de identidad o el uso de nuestros
                        servicios por parte de Partners de la App no autorizados.
                    </p>
                    <p class="aviso">También podemos usar selfies para verificar que los usuarios usen mascarilla, casco u otros equipos de seguridad mediante tecnología de verificación de objetos.</p>
                    <p class="aviso">Usar los datos del dispositivo, perfil de usuario, uso, entre otros, para prevenir, detectar y combatir otros tipos de fraude. Esto incluye identificar cuentas o usos fraudulentos de nuestros servicios, evitar el uso de los servicios
                        por parte de Partners de la App no autorizados, verificar la identidad de los usuarios en relación con ciertos métodos de pago y prevenir y combatir el acceso no autorizado a las cuentas de los usuarios. En algunas situaciones,
                        como cuando un usuario presenta documentación fraudulenta o usa de manera indebida los programas de beneficios de Baguu, este tipo de comportamientos puede ocasionar la desactivación automática. Para casos en la Unión 7 Europea
                        o cuando así lo exija la ley, la desactivación se realizará después de la revisión humana. Para oponerse a dicha desactivación, comuníquese con el soporte al cliente de Baguu.</p>
                    <p class="aviso">Usar las calificaciones, incidentes reportados y otros comentarios de los usuarios para promover el cumplimiento de nuestras Guías comunitarias y como motivo para desactivar las cuentas de usuarios con bajas calificaciones o que infringen
                        de otra manera dichas guías en ciertos países. En la Unión Europea o cuando lo exija la ley, la desactivación solo se producirá después de la revisión humana o de la posibilidad de apelación.</p>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para cumplir con los términos de nuestros acuerdos con los usuarios o por los intereses de seguridad legítimos de Baguu, nuestros usuarios y el público en general.</p>
                    <br>
                    <p class="aviso">3. Soporte al cliente. Baguu usa la información recopilada (que puede incluir grabaciones de llamadas) para brindar soporte al cliente, incluso para investigar y abordar las inquietudes de los usuarios, y para controlar y mejorar las
                        respuestas y los procesos de soporte al cliente.</p> <br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para cumplir con los términos de nuestros acuerdos con los usuarios o para satisfacer el interés legítimo de Baguu de monitorear y mejorar sus servicios de soporte al cliente.</p><br>
                    <p class="aviso">4. Investigación y desarrollo. Usamos los datos para pruebas, investigaciones, análisis, desarrollo de productos y aprendizaje automático con el fin de mejorar la experiencia del usuario. Esto permite mejorar la seguridad de nuestros
                        servicios, hacerlos más convenientes y fáciles de usar, y desarrollar nuevos servicios y funciones de mejora continua.
                    </p> <br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para satisfacer los intereses legítimos de Baguu de mejorar y desarrollar nuevos servicios y funciones.</p> <br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para cumplir con los términos de nuestros acuerdos con los usuarios.</p><br>
                    <p class="aviso">5. Marketing y publicidad. Baguu usa datos para comercializar sus servicios y los de los Partners de Baguu. Se incluyen las siguientes actividades:</p><br>
                    <p class="aviso">Enviar correos electrónicos, mensajes de texto, notificaciones push y mensajes en la app u otras comunicaciones de marketing o publicidad de productos, servicios, funciones, ofertas, promociones, sorteos, noticias y eventos de Baguu.</p><br>
                    <p class="aviso">Esto puede incluir el uso de pedidos y los datos del dispositivo y de uso para enviar comunicaciones de marketing personalizadas según los intereses y características observados o deducidos de los usuarios. Por ejemplo, podemos enviar
                        notificaciones push que sugieren los establecimientos favoritos de un usuario o mensajes en la app que ofrecen ahorros o promociones para productos o establecimientos similares a los que el usuario utilizó anteriormente. </p>
                    <p class="aviso">Mostrar publicidad de Baguu en apps o sitios web de terceros. Esto incluye usar el historial de pedidos y los datos del dispositivo y de uso, así como compartir direcciones de correo electrónico cifradas e identificadores de dispositivos
                        o usuarios con Partners publicitarios (como Facebook y TikTok), para personalizar dichos anuncios según los intereses de los usuarios.</p>
                    <p class="aviso">Mostrar publicidad de terceros en la app de Baguu o en relación con nuestros servicios.</p>
                    <p class="aviso">Esto incluye anuncios de restaurantes o establecimientos disponibles en la app de Baguu. Estos anuncios incluyen recomendaciones personalizadas según la ubicación y el historial de pedidos de los usuarios.</p>
                    <p class="aviso">Asimismo, se incluyen anuncios personalizados según los datos sobre la solicitud de entrega actuales, incluida la hora del pedido y los servicios solicitados.</p>
                    <p class="aviso">Medir la efectividad de los anuncios de Baguu y de los anuncios de terceros que se muestran en la app de Baguu o en relación con nuestros servicios.</p> <br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para satisfacer su interés legítimo de brindar información a los usuarios sobre los servicios y funciones de Baguu o de sus Partners. </p><br>
                    <p class="aviso">6. Comunicaciones no comerciales. Baguu puede usar los datos para enviar encuestas y otras comunicaciones que no tengan el fin de comercializar los servicios o productos de Baguu o de sus Partners. También podemos enviar comunicaciones
                        a los usuarios sobre elecciones, votaciones, referéndums y otros procesos políticos relacionados con nuestros servicios. </p>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para cumplir con los términos de nuestros acuerdos con los usuarios o para satisfacer los intereses legítimos de Baguu y de sus usuarios de brindarles información sobre
                        los eventos que pueden afectar al uso de los servicios que brinda la empresa.
                    </p><br>
                    <p class="aviso">7. Procedimientos y requisitos legales. Usamos datos para investigar o abordar reclamos o disputas relacionados con el uso de los servicios de Baguu, a fin de cumplir con los requisitos de las leyes, reglamentaciones, licencias o acuerdos
                        de operación vigentes, o en virtud de un procedimiento legal o de una solicitud gubernamental, incluso de la fuerza del orden público.</p><br>
                    <p class="aviso">Baguu realiza las actividades anteriores porque son necesarias para satisfacer los intereses legítimos de Baguu de investigar y de responder los reclamos y las disputas relacionados con el uso de los servicios y las funciones de Baguu,
                        o para cumplir con los requisitos legales aplicables.
                    </p><br>
                    <p class="aviso">C. Cookies y tecnologías de terceros
                    </p>
                    <p class="aviso">Baguu y sus Partners usan cookies y otras tecnologías de identificación en la app, sitios web, correos electrónicos y anuncios en línea para los fines descritos en este aviso y en el Aviso de cookies de Baguu.
                    </p><br>
                    <p class="aviso">Las cookies son pequeños archivos de texto que los sitios web, apps, medios en línea y anuncios almacenan en el navegador o en el dispositivo. Baguu usa cookies y tecnologías similares para los fines que se enumeran a continuación:
                    </p><br>
                    <p class="aviso">Autenticar usuarios</p>
                    <p class="aviso">Recordar las preferencias y configuraciones del usuario
                    </p>
                    <p class="aviso">Determinar la popularidad del contenido
                    </p>
                    <p class="aviso">Entregar campañas publicitarias y medir su eficacia
                    </p>
                    <p class="aviso">Analizar el tránsito y las tendencias del sitio para comprender los comportamientos en línea y los intereses de las personas que interactúan con nuestros servicios
                    </p>
                    <p class="aviso">También podemos permitir que terceros realicen mediciones de audiencia y servicios analíticos para nosotros, presenten anuncios en representación nuestra a través de internet y realicen un seguimiento y nos informen sobre el rendimiento
                        de estos anuncios. Estas entidades pueden usar cookies, balizas web, SDK y otras tecnologías para identificar los dispositivos que usan las personas cuando visitan nuestros sitios web, así como cuando ingresan a otros servicios
                        y sitios en línea.</p><br>
                    <p class="aviso">D. Intercambio y divulgación de datos
                    </p><br>
                    <p class="aviso">Algunos de los servicios y funciones de Baguu requieren que intercambiemos datos con otros usuarios o a pedido de estos. También podemos compartir esa información con nuestras filiales, subsidiarias y Partners por motivos legales o
                        relacionados con reclamos o disputas.</p><br>
                    <p class="aviso">Baguu puede compartir datos:
                    </p><br>
                    <p class="aviso">1. Con otros usuarios
                    </p><br>
                    <p class="aviso">Se incluyen los siguientes datos:
                    </p>
                    <p class="aviso">Nombre, dirección de entrega y detalles del pedido de los destinatarios con el restaurante o el establecimiento y, en el caso de las entregas, con el socio de la App. También podemos compartir calificaciones y comentarios, u otra información,
                        en la medida en que lo exija la ley, con el restaurante o el establecimiento y, para las entregas de pedidos, con el socio de la App.
                    </p>
                    <p class="aviso">También proporcionamos a los usuarios y a los destinatarios de pedidos recibos que incluyen información como el desglose de los montos cobrados. También incluimos otros datos en los recibos si así lo exige la ley.</p><br>
                    <p class="aviso">2. A pedido del usuario
                    </p><br>
                    <p class="aviso">Esto incluye compartir datos con las siguientes partes:
                    </p>
                    <p class="aviso">Partners comerciales de Baguu. Por ejemplo, si un usuario solicita un servicio a través de una asociación o de una oferta promocional realizada por un tercero, Baguu puede compartir ciertos datos con dichas partes. Esto puede incluir,
                        por ejemplo, otros servicios, plataformas, apps o sitios web que se integran con nuestras API, proveedores o servicios de vehículos, aquellos con una API o servicio que integramos, restaurantes, establecimientos u otros Partners
                        comerciales de Baguu y sus usuarios con relación a promociones, concursos o servicios especializados.</p><br>
                    <p class="aviso">3. Con el público en general
                    </p><br>
                    <p class="aviso">Las preguntas o comentarios de los usuarios que se envían a través de foros públicos, como los blogs y redes sociales de Baguu, pueden estar al alcance de todos, incluidos los datos incorporados en las preguntas o comentarios enviados
                        por un usuario.
                    </p><br>
                    <p class="aviso">4. Con el titular de la cuenta Baguu
                    </p><br>
                    <p class="aviso">Si un usuario recibe servicios a través de una cuenta que pertenece a otra persona, podemos compartir la información del pedido, con el propietario de esa cuenta. Esto sucede, por ejemplo, en los siguientes casos:</p><br>
                    <p class="aviso">Un usuario usa el perfil de Baguu para Empresas de su empleador.
                    </p>
                    <p class="aviso">Un socio de la App usa una cuenta que pertenece o que se vincula a establecimientos asociados con Baguu.
                    </p>
                    <p class="aviso">Además, si un usuario crea una cuenta con una dirección de correo electrónico asociada al titular de una cuenta Baguu para Empresas (es decir, el empleador), podemos usar y compartir los datos de su perfil (como el nombre y la dirección
                        de correo electrónico) con el titular de la cuenta para ayudar al usuario a rendir gastos de pedidos a esa cuenta Baguu para Empresas.
                    </p><br>
                    <p class="aviso">5. Con proveedores de servicios y Partners comerciales de Baguu
                    </p><br>
                    <p class="aviso">Entre estos se incluyen los siguientes:
                    </p>
                    <p class="aviso">Procesadores y facilitadores de pago
                    </p>
                    <p class="aviso">Proveedores de verificación de antecedentes y de identidad
                    </p>
                    <p class="aviso">Proveedores de almacenamiento en la nube
                    </p><br>
                    <p class="aviso">Google, en relación con el uso de Google Maps en la app de Baguu (consulte la Política de privacidad de Google para obtener información sobre la recopilación y el uso de los datos)</p>
                    <p class="aviso">Empresas de redes sociales, incluso Facebook y TikTok, en relación con el uso que hace Baguu de las funciones en la app y sitios web de Baguu (consulte la Política de privacidad de Facebook y la Política de privacidad de TikTok para
                        obtener información sobre cómo recopilan y usan los datos) </p>
                    <p class="aviso">Partners de marketing y proveedores de plataformas de marketing, incluidos los servicios de publicidad en redes sociales, redes de publicidad, proveedores de datos externos y otros proveedores de servicios para llegar o comprender
                        mejor a nuestros usuarios y medir la eficacia de la publicidad
                    </p><br>
                    <p class="aviso">Esto incluye intermediarios de publicidad, como Google que permiten a Baguu mostrar y medir la eficacia de anuncios personalizados de productos de terceros que se muestran en la app de Baguu.</p>
                    <p class="aviso">Socios de investigación, incluidos aquellos que realizan encuestas o proyectos de investigación asociados con Baguu o en su nombre
                    </p>
                    <p class="aviso">Proveedores que ayudan a Baguu a mejorar la seguridad de la app y los servicios de Baguu
                    </p>
                    <p class="aviso">Consultores, abogados, contadores y otros proveedores de servicios profesionales
                    </p>
                    <p class="aviso">Socios financieros y de seguro</p><br>
                    <p class="aviso">6. Por motivos legales o ante una disputa
                    </p><br>
                    <p class="aviso">Baguu puede compartir los datos de los usuarios si así lo exige la ley, reglamentación, licencia o acuerdo de operación, proceso legal o solicitud gubernamental vigentes, o cuando la divulgación es apropiada debido a asuntos de seguridad
                        o similares.</p> <br>
                    <p class="aviso">Esto incluye compartir datos con la fuerza del orden público, funcionarios de salud pública, otras entidades gubernamentales u otros terceros, según sea necesario para hacer cumplir nuestros Términos del servicio, acuerdos del usuario
                        u otras políticas, para proteger los derechos o la propiedad de Baguu, o los derechos, la seguridad o la propiedad de los demás, o en caso de un reclamo o una disputa relacionados con el uso de nuestros servicios. En caso de una
                        disputa por el uso de la tarjeta de crédito de otra persona, es posible que la ley nos exija compartir sus datos con el titular, incluida la información del pedido.</p><br>
                    <p class="aviso">Esto también incluye compartir datos con terceros en relación con las negociaciones de cualquier fusión con otra empresa, venta de activos empresariales, consolidación o reestructuración, financiación o adquisición de toda o una parte
                        de nuestro negocio por parte de otra empresa, o mientras se lleven a cabo.
                    </p><br>
                    <p class="aviso">7. Con consentimiento</p> <br>
                    <p class="aviso">Baguu puede compartir los datos de un usuario de manera diferente a la descrita en este aviso si se lo notificamos y este acepta el intercambio de información.
                    </p><br>
                    <p class="aviso">E. Conservación y eliminación de los datos</p>
                    <p class="aviso">Baguu conserva los datos del usuario durante el tiempo que sea necesario para los fines descritos anteriormente, incluso para proporcionar sus servicios y cumplir con las obligaciones legales.</p><br>
                    <p class="aviso">Baguu conserva los datos del usuario por el tiempo que sea necesario para cumplir con los fines descritos anteriormente. El periodo durante el cual conservamos los datos del usuario está determinado por el tipo de dato, la categoría
                        de usuario con el que se relacionan y los fines para los que los recopilamos.</p><br>
                    <p class="aviso">El tiempo que Baguu conserva los datos del usuario también puede estar determinado por requisitos legales y reglamentarios, fines de seguridad y prevención de fraude o problemas relacionados con la cuenta del usuario, como créditos
                        pendientes o un reclamo o disputa sin resolver.
                    </p><br>
                    <p class="aviso">Por ejemplo, conservamos datos:</p><br>
                    <p class="aviso">Durante la vigencia de las cuentas de los usuarios si dichos datos son necesarios para brindar nuestros servicios, p. ej., información del perfil de usuario y credenciales.
                    </p>
                    <p class="aviso">Después de solicitar la eliminación de la cuenta si es necesario por motivos de seguridad, protección, prevención de fraude o cumplimiento de los requisitos legales, o debido a problemas relacionados con la cuenta del usuario (como
                        un crédito pendiente o un reclamo o disputa no resuelto).
                    </p>
                    <p class="aviso">Los usuarios pueden solicitar la eliminación de sus cuentas en cualquier momento. Baguu puede conservar los datos del usuario después de recibir una solicitud de eliminación debido a requisitos legales o reglamentarios o por los motivos
                        que se establecen en esta política.
                    </p><br>
                    <p class="aviso">Los usuarios pueden solicitar la eliminación de su cuenta en cualquier momento a través del menú Ayuda en la app y enviar su petición directamente.</p><br>
                    <p>Después de recibir la solicitud, Baguu elimina la cuenta y los datos del usuario, a menos que se conserven debido a requisitos legales o reglamentarios, con fines de seguridad o de prevención de fraude, o por un problema relacionado
                        con la cuenta del usuario, como créditos pendientes o un reclamo o disputa sin resolver. Debido a que estamos sujetos a requisitos legales y reglamentarios relacionados con los Partners de la App, conservaremos su cuenta y sus
                        datos por el periodo de retención legal correspondiente después de una solicitud de eliminación. En el caso de los usuarios y destinatarios de pedidos, sus datos suelen eliminarse dentro de los 90 días posteriores a la solicitud,
                        excepto cuando su conservación sea necesaria por los motivos descritos anteriormente.</p><br>
                    <h5 class="text_H5">IV. Opciones y transparencia</h5><br>
                    <p class="aviso">Baguu permite a los usuarios acceder o controlar los datos que recopila, incluso a través de los siguientes medios: </p><br>
                    <p class="aviso">Configuración de privacidad
                    </p>
                    <p class="aviso">Permisos del dispositivo
                    </p>
                    <p class="aviso">Calificaciones en la app</p><br>
                    <p class="aviso">Baguu también permite a los usuarios que soliciten acceso a sus datos o copias de estos, que hagan cambios o actualizaciones en sus cuentas o que soliciten la eliminación de sus cuentas o que Baguu restrinja el procesamiento de su
                        información.
                    </p><br>
                    <p class="aviso">A. Configuración de privacidad
                    </p><br>
                    <p class="aviso">Notificaciones: Actualizaciones de cuenta
                    </p>
                    <p class="aviso">Baguu envía a los usuarios notificaciones sobre actualizaciones relacionadas con sus cuentas. Estos avisos son necesarios para usar la app de Baguu y no pueden desactivarse. </p><br>
                    <p class="aviso">Notificaciones: Ahorros y noticias
                    </p>
                    <p class="aviso">Los usuarios pueden permitir que Baguu les envíe notificaciones push con noticias de la empresa.
                    </p><br>
                    <p class="aviso">Comunicaciones de establecimientos
                    </p><br>
                    <p class="aviso">Al hacer un pedido en la app de Baguu, los usuarios pueden optar por compartir sus datos de contacto con ciertos establecimientos para recibir sus comunicaciones. </p><br>
                    <p class="aviso">B. Permisos del dispositivo
                    </p><br>
                    <p class="aviso">La mayoría de las plataformas de dispositivos móviles (iOS, Android, etc.) definieron ciertos tipos de datos del dispositivo a los que la app no pueden acceder sin la autorización del propietario. Estas plataformas tienen diferentes
                        métodos para obtener dichos permisos. Verifique la configuración disponible en su dispositivo o consulte con su proveedor.</p><br>
                    <p class="aviso">C. Calificaciones en la app
                    </p><br>
                    <p class="aviso">Después de cada pedido, los usuarios pueden calificar el servicio con una escala del 1 al 5. El promedio de esas calificaciones se asocia con la cuenta de un usuario y se muestra a las personas a quienes proporcionan o de quienes reciben
                        servicios. Por ejemplo, los Partners de la App pueden ver las calificaciones de los usuarios que agendan citas, compran productos y/o solicitan servicios.</p><br>
                    <p class="aviso">Mediante este sistema de calificación mutua, todas las personas son responsables de su propio comportamiento, lo que ayuda a crear un ambiente respetuoso y seguro para los usuarios y para los Partners de la App.</p><br>
                    <p class="aviso">Los usuarios pueden ver su calificación promedio en el menú principal de la app de Baguu y acceder a un desglose de su calificación promedio en el Centro de privacidad de Baguu.
                    </p><br>
                    <p class="aviso">Los Partners de la App pueden ver su calificación promedio al tocar su foto de perfil en la app para Partners conductores. Haga clic aquí para conocer más.</p><br>
                    <p class="aviso">Los Partners repartidores también reciben calificaciones de los destinatarios de pedidos, restaurantes y establecimientos. Haga clic aquí para conocer más.</p><br>
                    <p class="aviso">D. Opciones de marketing y publicidad
                    </p><br>
                    <p class="aviso">Baguu ofrece a los usuarios las siguientes opciones con respecto a la manera en que se usan sus datos con fines de marketing y publicidad:
                    </p><br>
                    <p class="aviso">Comunicaciones de marketing personalizadas de Baguu: Los usuarios pueden acceder aquí para elegir si quieren usar sus datos para el envío de comunicaciones personalizadas (como correos electrónicos, notificaciones push y mensajes en
                        la app) sobre los productos y servicios de Baguu. Además, los usuarios pueden acceder aquí para elegir si quieren recibir notificaciones push o correos electrónicos de marketing de Baguu.</p>
                    <p class="aviso">Anuncios de Baguu: Los usuarios pueden acceder aquí para elegir si sus datos se usan y se comparten con Partners publicitarios para mostrar anuncios personalizados sobre productos y servicios de Baguu en apps y sitios web de terceros
                        o para medir la efectividad de dichos anuncios.</p>
                    <p class="aviso">Anuncios de restaurantes y establecimientos: Los usuarios pueden acceder aquí para elegir si Baguu puede usar su historial de pedidos y de búsqueda para mostrar anuncios personalizados de los restaurantes o establecimientos que están
                        disponibles en la app de Baguu.</p>
                    <p class="aviso">Anuncios de terceros: Los usuarios pueden acceder aquí para elegir si Baguu puede usar sus datos para mostrar anuncios de productos o servicios de terceros que se personalicen según los intereses y características observados o deducidos
                        de los usuarios.
                    </p>
                    <p class="aviso">Cookies y tecnologías relacionadas: Para obtener información sobre cómo controlar el uso de cookies y tecnologías relacionadas de Baguu, incluso con el fin de mostrar anuncios personalizados, consulte nuestro Aviso de cookies.</p><br>
                    <p class="aviso">E. Solicitud de datos del usuario
                    </p><br>
                    <p class="aviso">Baguu ofrece a los usuarios diversas maneras de conocer, controlar y enviar preguntas y comentarios sobre el tratamiento de sus datos por parte de Baguu.
                    </p><br>
                    <p class="aviso">Acceso a datos: Los usuarios pueden acceder a los datos de su perfil y al historial de pedidos a través de la app o el sitio web de Baguu. Los usuarios también pueden solicitar acceso a sus datos.
                    </p>
                    <p class="aviso">Portabilidad de los datos: Los usuarios pueden solicitar una copia de sus datos a través de la función Descarga tus datos. Para obtener una descripción general de los datos disponibles mediante esta función, haga clic aquí. Los usuarios
                        también pueden solicitar una copia de sus datos (aquí pueden hacerlo los usuarios y los destinatarios de los pedidos y aquí los Partners de la App).
                    </p>
                    <p class="aviso">Cambio o actualización de datos: Los usuarios pueden editar el nombre, número de teléfono, dirección de correo electrónico, método de pago y foto asociados con su cuenta a través del menú Configuración en la app de Baguu o en el portal
                        para Partners de la App. Los usuarios también pueden solicitar actualizar o corregir sus datos (aquí pueden hacerlo los usuarios y los destinatarios de los pedidos y aquí los Partners de la App).</p>
                    <p class="aviso">Eliminación de datos: Los usuarios pueden solicitar la eliminación de su cuenta en cualquier momento en el Centro de privacidad de Baguu o desde el menú Configuración > Privacidad en la app de Baguu.</p>
                    <p class="aviso">Objeciones, restricciones y reclamos: Los usuarios pueden solicitar que dejemos de usar todos sus datos o parte de ellos, o bien que limitemos su uso (aquí pueden hacerlo los usuarios y destinatarios de pedidos y aquí los Partners
                        de la App). Esto incluye oponerse al uso de información que se basa en intereses legítimos de Baguu. Baguu puede continuar con el procesamiento de datos después de dicha objeción o solicitud en la medida en que la ley lo requiera
                        o lo permita.
                    </p><br>
                    <p class="aviso">Además, según su ubicación, los usuarios pueden tener derecho a presentar un reclamo relacionado con el tratamiento de sus datos por parte de Baguu ante la autoridad de protección de datos en su país. Por ejemplo, los usuarios de la
                        Unión Europea y de Sudamérica pueden enviar dichos reclamos a las autoridades de protección de datos del país en el que viven.
                    </p>
                    <p class="aviso">Apelación: Los usuarios que residen en EE. UU. pueden solicitar una revisión de nuestra decisión en caso de que Baguu no pueda cumplir con la solicitud de datos de un usuario o la rechace, y pueden solicitar una apelación de nuestra
                        decisión aquí.
                    </p><br>
                    <h5 class="text_H5">V. Actualizaciones de este aviso
                    </h5><br>
                    <p class="aviso">Es posible que actualicemos este aviso ocasionalmente.
                    </p><br>
                    <p class="aviso">Es posible que actualicemos este aviso ocasionalmente. Si realizamos cambios significativos, se los notificaremos a los usuarios a través de la app de Baguu o por otros medios, como por correo electrónico. Recomendamos a los usuarios
                        revisar periódicamente este aviso para obtener información actualizada sobre nuestras prácticas de privacidad.
                    </p><br>
                    <p class="aviso">El uso de nuestros servicios después de una actualización constituye el consentimiento del aviso modificado en la medida en que la ley lo permita.</p>

                </div>
             </ion-content> 

    </ng-template>
</ion-modal>