<ion-menu contentId="main-content" #menu>
    <ion-header>
        <ion-toolbar class="menu-toolbar-header">
            <div class="styleMenu">
                <div class="img_Logo">
                    <img src="assets/img/LOGO_HORIZONTAL.png" alt="logo" style="width: 100%;">
                </div>

                <div class="profile-name ion-text-center ion-margin-top">
                    <h2>¡Hola {{name}}!</h2>
                    <ion-button fill="clear" (click)="openProfile()">Editar perfil</ion-button>
                </div>
                <div class="profile-img  ion-text-center ion-margin-top" slot="end">
                    <div class="profile-page-image">
                        <ion-icon *ngIf="!picture" src="assets/svg/user-photo-placeholder.svg" class="profile-avatar"></ion-icon>
                        <img class="profile-picture profile-avatar" *ngIf="picture" [src]="picture" />
                        <ion-menu-toggle class="profile-edit-icon-wrapper" fill="clear" (click)="openProfile()">
                            <ion-icon name="create-outline" class="profile-edit-icon"></ion-icon>
                        </ion-menu-toggle>
                    </div>
                </div>
            </div>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding profile-menu">
        <ion-list>
            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="openAddresses()">
                    <ion-icon src="assets/svg/address-icon.svg" alt="address-icon" slot="start"></ion-icon>
                    Domiclio
                </ion-button>
            </ion-item>
            <!-- <ion-menu-toggle>
                <ion-item lines="none">
                    <ion-button fill="clear" size="default" (click)="openPaymentMethods()">
                        <ion-icon src="assets/svg/bank-icon.svg" alt="bank-icon" slot="start"></ion-icon>
                        Datos bancarios
                    </ion-button>
                </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
                <ion-item lines="none">
                    <ion-button fill="clear" size="default" (click)="openBilling()">
                        <ion-icon src="assets/svg/billing-icon.svg" alt="billing-icon" slot="start"></ion-icon>
                        Datos fiscales
                    </ion-button>
                </ion-item>
            </ion-menu-toggle> -->
            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="openNotifications()">
                    <ion-icon src="assets/svg/notifications-icon.svg" alt="notifications-icon" slot="start"></ion-icon>
                    <ion-badge class="cart-badge notification-badge" [hidden]="notificationsCount &lt;= 0">{{ notificationsCount }}</ion-badge>
                    Notificaciones
                </ion-button>
            </ion-item>



            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="openOrdersHistory()">
                    <ion-icon src="assets/svg/history-icon.svg" alt="history-icon" slot="start"></ion-icon>
                    Historial de pedidos
                </ion-button>
            </ion-item>
     
            <!-- <ion-menu-toggle>

                <ion-item lines="none">
                    <ion-button fill="clear" size="default">
                        <ion-icon src="assets/svg/offer-icon.svg" alt="offers-icon" slot="start"></ion-icon>
                        Promociones
                    </ion-button>
                </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
                <ion-item lines="none">
                    <ion-button fill="clear" size="default">
                        <ion-icon src="assets/svg/help-icon.svg" alt="help-icon" slot="start"></ion-icon>
                        Ayuda
                    </ion-button>
                </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>

                <ion-item lines="none">
                    <ion-button fill="clear" size="default">
                        <ion-icon src="assets/svg/lock-icon.svg" alt="security-icon" slot="start"></ion-icon>
                        Seguridad
                    </ion-button>
                </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
                <ion-item lines="none">
                    <ion-button fill="clear" size="default">
                        <ion-icon src="assets/svg/info-icon.svg" alt="info-icon" slot="start"></ion-icon>
                        Información
                    </ion-button>
                </ion-item>
            </ion-menu-toggle> -->
            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="terminos()">
                    <ion-icon src="assets/svg/terms-and-conditions-icon.svg" alt="terms-icon" slot="start"></ion-icon>
                    <app-terms-and-conditions></app-terms-and-conditions>
                </ion-button>
            </ion-item>
            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="openAviso()">
                    <ion-icon src="assets/svg/terms-and-conditions-icon.svg" alt="terms-icon" slot="start"></ion-icon>
                    <div class="custom-container">
                        <app-privacy-policy></app-privacy-policy>
                    </div>

                </ion-button>
            </ion-item>
            <ion-item lines="none">
                <ion-button fill="clear" size="default" (click)="openHelp()">
                    <ion-icon src="assets/svg/help-icon.svg" alt="help-icon" slot="start"></ion-icon>
                    Ayuda
                </ion-button>
            </ion-item>
            <ion-item lines="none" button (click)="confirmLogout()">
                <ion-button fill="clear" size="default">
                    <ion-icon src="assets/svg/logout-icon.svg" alt="help-icon" slot="start"></ion-icon>
                    Cerrar sesión
                </ion-button>
            </ion-item>
        </ion-list>
    </ion-content>
    <ion-footer>
        <ion-grid>
            <ion-row class="version-label ion-text-center">
                <ion-col><span>{{appVersion}}</span></ion-col>
            </ion-row>
        </ion-grid>
    </ion-footer>
</ion-menu>