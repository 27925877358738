
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CartNotificationsController {

    constructor(
        private toastController: ToastController,
        private router: Router,
        private navCtrl: NavController,
    ) { }
    async showCartAlert(cartItems: number, position: 'top' | 'middle' | 'bottom', clasess: string) {
        const alert = await this.toastController.create({
            message: 'Productos agregados a carrito',
            duration: 3000,
            position: position,
            cssClass: `cart-notification ${clasess}`,
            buttons: [
                {
                    text: 'Ver carrito (' + cartItems + ')',
                    role: 'cancel',
                    cssClass: 'cart-notification-btn'
                }
            ],
        });
        alert.onDidDismiss().then(val => {
            if (val.role == "cancel") {
                // this.router.navigateByUrl('/home/cart', { replaceUrl: true});
                this.navCtrl.navigateForward('/home/cart', { animated: false, replaceUrl: true });
            }
        })
        await alert.present();
    }
}
