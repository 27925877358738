// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    firebase: {
        projectId: 'ecosistema-378016',
        appId: '1:876019800692:web:2964bd6b5712ae65c18f21',
        storageBucket: 'ecosistema-378016.appspot.com',
        apiKey: 'AIzaSyC2ua3eUlM9IuC-H1B2hz_tp7IxzBxlLpo',
        authDomain: 'ecosistema-378016.firebaseapp.com',
        messagingSenderId: '876019800692',
        measurementId: 'G-2HMKZW3077',
    },
    production: false,
    mapache: {
        version: 'Debug v1.03.24.4',
        hosts: {
            ecosistema: 'https://dev-back.baguu.mx/cassandra',
            notifications: 'https://dev-back.baguu.mx/notifications',
            wallet: 'https://dev-back.baguu.mx/virtualcard',
            sales: 'https://dev-back.baguu.mx/payments',
        },
        endpoints: {
            categories: '/business/type',
            businesses: '/business/position',
            business: '/owner/{ownerId}/business',
            businessId: '/business/{businessId}',
            products: '/owner/{ownerId}/business/{businessId}/catalogue',
            address: '/addresses',
            ordersHistory: '/appointment/customer/mail/{email}/month/{firstDayMonth}',
            ordersHistoryListSales: '/sales/mail/{email}/month/{dayMonth}/now/{today}',
            receipt: '/sales/receipt/{saleId}',
            appointment: '/owner/{ownerId}/business/{businessId}/appointment',
            appointmentUpdate: '/appointment/{appointmentId}/status/{statusId}',
            notifications: {
                list: '/user/{userId}/status/1,2',
                seen: '/{notificationId}/status/2',
                delete: '/{notificationId}',
            },
            sales: '/owner/{ownerId}/business/{businessId}/sale',
            qualify: '/owner/{ownerId}/business/{businessId}/sale/{saleId}/score/{scoreId}',
            scoreImage: '/owner/{ownerId}/business/{businessId}/score',
            cardTransactions: '/sales/card/{cardId}',
            wallet: {
                cards: '/card/user-find/{ownerId}',
                addBalance: '/card/balance/add',
                createCard: '/card'
            },
            salesMit: {
                card: '/card',
                reference: '/reference/{referenceId}'
            },
            salesPraga: {
                card: '/praga',
                reference: '/reference/{referenceId}'
            },
            help: '/mail/contact',
            shoppingCart: {
                cartForId: '/shopping-cart/{cartId}',
                cartForRequest: '/shopping-cart',
                cartForCustomer: '/shopping-cart/customer/{id}'
            },
        },
    }, events: {
        url: 'https://dev-back.baguu.mx',
        tag: '/tags',
    },
    urlMaps: 'https://www.google.com/maps/search/?api=1&query={lat}%2C{lng}&query_place_id={placeid}',
    baguuBusines: '1',
    makePayment: false,
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
