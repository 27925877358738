import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, firstValueFrom, } from 'rxjs';
import { CartNotificationsController } from '../controllers/cart-notifications.controller';
import { PersonalizersService } from './personalizers.service';
import { HttpService } from './http.service';
import { EndpointsService } from './endpoints.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private cartQuantity$: Subject<number>;
    private cartBusinessPraga$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private appointmentDate$: BehaviorSubject<any> = new BehaviorSubject<any>({});

    //with this variable we can inicializate the car again
    private inicializateCart$: Subject<boolean> = new Subject<boolean>();

    /* modals */
    private isOpenModalPay$: Subject<boolean> = new Subject<boolean>();

    private isOpenModalMethod$: Subject<any> = new Subject<any>();
    //private isOpenModalMethod$: Subject<boolean> = new Subject<boolean>();

    private isOpenModalSuccess$: Subject<boolean> = new Subject<boolean>();
    private isOpenModalDenied$: Subject<boolean> = new Subject<boolean>();
    private isPaymentMethodComplete$: Subject<boolean> = new Subject<boolean>();
    private isPaymentMethodFinish$: Subject<boolean> = new Subject<boolean>();
    private isCardMethod$: Subject<boolean> = new Subject<boolean>();

    public receiptId?: any;


    private _cart = {
        products: <any>[],
    };

    customerId: any;
    public resultShoppingCart: any = [];
    public shoppingCartId: any = 0;
    public isConsultCard: boolean = false;
    public addProductCart: boolean = false;

    constructor(
        private personalizersService: PersonalizersService,
        private cartNotification: CartNotificationsController,
        private authService: AuthService,
        private httpService: HttpService,
        private endpoints: EndpointsService,
    ) {
        this.cartQuantity$ = new Subject();
        this.customerId = this.authService.uid;
    }

    get productsCount() {
        var count = 0;
        this._cart.products.forEach((business: any) => {
            count += business.products.length;
        });
        return count;
    }

    get products() {
        return this._cart.products;
    }

    getCartQuantity$(): Observable<number> {
        return this.cartQuantity$.asObservable();
    }

    getAppointmentDate$(): Observable<any> {
        return this.appointmentDate$.asObservable();
    }

    //
    getinicializateCart$(): Observable<any> {
        return this.inicializateCart$.asObservable();
    }

    getCartBusinessPraga$(): Observable<any> {
        return this.cartBusinessPraga$.asObservable();
    }

    /* modals */
    getOpenModalMethod$(): Observable<any> {
        return this.isOpenModalMethod$.asObservable();
    }

    getOpenModalPay$(): Observable<any> {
        return this.isOpenModalPay$.asObservable();
    }

    getOpenModalSuccess$(): Observable<any> {
        return this.isOpenModalSuccess$.asObservable();
    }

    getOpenModalDenied$(): Observable<any> {
        return this.isOpenModalDenied$.asObservable();
    }

    getPaymentMethodComplete$(): Observable<any> {
        return this.isPaymentMethodComplete$.asObservable();
    }

    getPaymentMethodFinish$(): Observable<any> {
        return this.isPaymentMethodFinish$.asObservable();
    }

    getCardMethod$(): Observable<any> {
        return this.isCardMethod$.asObservable();
    }

    sendAppointmentDate(appointment: any) {
        this.appointmentDate$.next(appointment);
    }

    sendInicializateCart(flag: boolean) {
        this.inicializateCart$.next(flag);
    }

    sendCartBusinessPraga$(businessPragaId: any) {
        this.cartBusinessPraga$.next(businessPragaId);
    }

    /* modals */
    setOpenModalMethod(flag: any) {
        this.isOpenModalMethod$.next(flag);
    }

    setOpenModalPay(flag: boolean) {
        return this.isOpenModalPay$.next(flag);
    }

    setOpenModalSuccess(flag: boolean) {
        return this.isOpenModalSuccess$.next(flag);
    }

    setOpenModalDenied(flag: boolean) {
        return this.isOpenModalDenied$.next(flag);
    }

    setPaymentMethodComplete(flag: boolean) {
        return this.isPaymentMethodComplete$.next(flag);
    }

    setPaymentMethodFinish(flag: boolean) {
        return this.isPaymentMethodFinish$.next(flag);
    }

    setCardMethod(flag: boolean) {
        return this.isCardMethod$.next(flag);
    }

    addProduct(product: any, businessTheme: string, saleAppointment?: any) {
        //calculate discount
        let discountPrice = this.calculateDiscount(product.discounts?.type,
            product.discounts?.value, String(product.subTotal), product.quantity);

        if (this.isConsultCard) {
            // Check if the business is already in the cart
            let businessIndex = this._cart.products.findIndex((business: any) => {
                return Number(business.id) === Number(product.businessId);
            });

            if (businessIndex === -1) {
                // Add the business
                this._cart.products.push({
                    id: product.businessId,
                    name: product.businessName,
                    ownerId: product.ownerId,
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            description: product.description,
                            comments: product.comments,
                            price: product.price,
                            quantity: product.quantity,
                            dateRegister: product.dateRegister,
                            timeRegister: product.timeRegister,
                            subTotal: product.subTotal,
                            imageURL: product.imageURL,
                            discount: product.discounts,
                            priceDiscount: discountPrice,
                        }
                    ],
                });
                // Notify cart quantity updated
                this.cartQuantity$.next(this.productsCount);

            } else {
                // Check if the product is already in the cart
                let productIndex = this._cart.products[businessIndex].products
                    .findIndex((productItem: any) => {
                        return Number(productItem.id) === Number(product.id);
                    });
                if (productIndex === -1) {
                    // Add the product
                    this._cart.products[businessIndex].products.push({
                        id: product.id,
                        name: product.name,
                        description: product.description,
                        comments: product.comments,
                        price: product.price,
                        quantity: product.quantity,
                        dateRegister: product.dateRegister,
                        timeRegister: product.timeRegister,
                        subTotal: product.subTotal,
                        imageURL: product.imageURL,
                        discount: product.discounts,
                        priceDiscount: discountPrice,
                    });

                    //Add product update shopping cart
                    if (this.addProductCart) {
                        let body = {
                            id: product.id,
                            name: product.name,
                            description: product.description,
                            comments: product.comments,
                            price: product.price,
                            quantity: product.quantity,
                            dateRegister: product.dateRegister,
                            timeRegister: product.timeRegister,
                            subTotal: product.subTotal,
                            imageURL: product.imageURL,
                            discount: product.discounts,
                            priceDiscount: discountPrice,
                        };
                        this.updateShoppingCart(body, saleAppointment).subscribe((result: any) => {
                            console.log('Result update', result.data);
                        });
                    }

                    this.addProductCart = false;

                    // Notify cart quantity updated
                    this.cartQuantity$.next(this.productsCount);
                } else {
                    // Update the product
                    this._cart.products[businessIndex].products[productIndex].quantity = product.quantity;
                    this._cart.products[businessIndex].products[productIndex].subTotal = product.subTotal;
                }
            }
        } else {
            // Check if the business is already in the cart
            let businessIndex = this._cart.products.findIndex((business: any) => { return business.id === product.businessId; });
            if (businessIndex === -1) {
                // Add the business
                this._cart.products.push({
                    id: product.businessId,
                    name: product.businessName,
                    ownerId: product.ownerId,
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            description: product.description,
                            comments: product.comments,
                            price: product.price,
                            quantity: product.quantity,
                            dateRegister: product.dateRegister,
                            timeRegister: product.timeRegister,
                            subTotal: product.subTotal,
                            imageURL: product.imageURL,
                            discount: product.discounts,
                            priceDiscount: discountPrice,
                        }
                    ],
                });
                // Notify cart quantity updated
                this.cartQuantity$.next(this.productsCount);

                // Show cart alert
                this.cartNotification.showCartAlert(this.productsCount, "bottom", businessTheme);


                //Save shopping cart first product
                if (this.resultShoppingCart.length === 0) {
                    console.log('Entro cuando resultShoppingCart no tiene datos');
                    let body = {
                        id: product.businessId,
                        name: product.businessName,
                        ownerId: product.ownerId,
                        products: [
                            {
                                id: product.id,
                                name: product.name,
                                description: product.description,
                                comments: product.comments,
                                price: product.price,
                                quantity: product.quantity,
                                dateRegister: product.dateRegister,
                                timeRegister: product.timeRegister,
                                subTotal: product.subTotal,
                                imageURL: product.imageURL,
                                discount: product.discounts,
                                priceDiscount: discountPrice,
                            }
                        ],
                    };

                    this.saveShoppingCart(body, saleAppointment).subscribe((result: any) => {
                        console.log('Result save', result.data);
                        this.resultShoppingCart = result.data;
                        this.shoppingCartId = result.data.id;
                    });
                }
            } else {
                // Check if the product is already in the cart
                let productIndex = this._cart.products[businessIndex].products.findIndex((productItem: any) => { return productItem.id === product.id; });
                if (productIndex === -1) {
                    // Add the product
                    this._cart.products[businessIndex].products.push({
                        id: product.id,
                        name: product.name,
                        description: product.description,
                        comments: product.comments,
                        price: product.price,
                        quantity: product.quantity,
                        dateRegister: product.dateRegister,
                        timeRegister: product.timeRegister,
                        subTotal: product.subTotal,
                        imageURL: product.imageURL,
                        discount: product.discounts,
                        priceDiscount: discountPrice,
                    });

                    // Notify cart quantity updated
                    this.cartQuantity$.next(this.productsCount);

                    // Show cart alert
                    this.cartNotification.showCartAlert(this.productsCount, "bottom", businessTheme);

                    //Add product update shopping cart
                    let body = {
                        id: product.id,
                        name: product.name,
                        description: product.description,
                        comments: product.comments,
                        price: product.price,
                        quantity: product.quantity,
                        dateRegister: product.dateRegister,
                        timeRegister: product.timeRegister,
                        subTotal: product.subTotal,
                        imageURL: product.imageURL,
                        discount: product.discounts,
                        priceDiscount: discountPrice,
                    };
                    this.updateShoppingCart(body, saleAppointment).subscribe((result: any) => {
                        console.log('Result update', result.data);
                    });
                } else {
                    // Update the product
                    this._cart.products[businessIndex].products[productIndex].quantity = product.quantity;
                    this._cart.products[businessIndex].products[productIndex].subTotal = product.subTotal;
                }
            }
        }
    }

    subTotal(businessIndex: number) {
        let subTotal = 0;
        this._cart.products[businessIndex].products.forEach((product: any) => {
            //subTotal += product.subTotal;
            //discounts
            subTotal += product.priceDiscount ? product.priceDiscount : product.subTotal;
        });
        return subTotal;
    }

    total() {
        let total = 0;
        this._cart.products.forEach((business: any) => {
            business.products.forEach((product: any) => {
                //total += product.subTotal;
                //discounts
                total += product.priceDiscount ? product.priceDiscount : product.subTotal;
            });
        });
        return total;
    }

    getPersonalizers(productId: string) {
        return this.personalizersService.getPersonalizers(productId);
    }

    lastBusiness(businessIndex: number) {
        return businessIndex === this._cart.products.length - 1;
    }

    dropProduct(businessIndex: number, productIndex: number) {
        this._cart.products[businessIndex].products.splice(productIndex, 1);
        if (this._cart.products[businessIndex].products.length === 0) {
            this._cart.products.splice(businessIndex, 1);
        }
        // Notify cart quantity updated
        this.cartQuantity$.next(this.productsCount);
    }

    quantityIncrement(businessIndex: number, productIndex: number) {
        if (this._cart.products[businessIndex].products[productIndex]?.discount?.type === 'product') {
            this._cart.products[businessIndex].products[productIndex].quantity += Number(this._cart.products[businessIndex].products[productIndex]?.discount?.value);
        } else {
            this._cart.products[businessIndex].products[productIndex].quantity++;
        }
        const quantity = this._cart.products[businessIndex].products[productIndex].quantity;
        const personalizersAmount = this.personalizersService.getPersonalizersTotalAmount(this._cart.products[businessIndex].products[productIndex].id) * quantity;
        const price = this._cart.products[businessIndex].products[productIndex].price;
        this._cart.products[businessIndex].products[productIndex].subTotal = (price * quantity) + personalizersAmount;
        //discount
        this._cart.products[businessIndex].products[productIndex].priceDiscount =
            this.calculateDiscount(this._cart.products[businessIndex].products[productIndex].discount.type,
                this._cart.products[businessIndex].products[productIndex].discount.value,
                String(this._cart.products[businessIndex].products[productIndex].subTotal), quantity);

    }

    quantityDecrement(businessIndex: number, productIndex: number) {
        if (this._cart.products[businessIndex].products[productIndex].quantity > 1) {
            if (this._cart.products[businessIndex].products[productIndex]?.discount?.type === 'product') {
                this._cart.products[businessIndex].products[productIndex].quantity -= Number(this._cart.products[businessIndex].products[productIndex]?.discount?.value);
            } else {
                this._cart.products[businessIndex].products[productIndex].quantity--;
            }
            const quantity = this._cart.products[businessIndex].products[productIndex].quantity;
            const personalizersAmount = this.personalizersService.getPersonalizersTotalAmount(this._cart.products[businessIndex].products[productIndex].id) * quantity;
            const price = this._cart.products[businessIndex].products[productIndex].price;
            this._cart.products[businessIndex].products[productIndex].subTotal = (price * quantity) + personalizersAmount;
            //discount
            this._cart.products[businessIndex].products[productIndex].priceDiscount =
                this.calculateDiscount(this._cart.products[businessIndex].products[productIndex].discount.type,
                    this._cart.products[businessIndex].products[productIndex].discount.value,
                    String(this._cart.products[businessIndex].products[productIndex].subTotal), quantity);

        }
    }

    changeAppointmentDateAndTime(businessIndex: number, productIndex: number, date: string, time: string) {
        this._cart.products[businessIndex].products[productIndex].dateRegister = date;
        this._cart.products[businessIndex].products[productIndex].timeRegister = time;
    }

    emptyCart() {
        this._cart.products = [];
        // Notify cart quantity updated
        this.cartQuantity$.next(this.productsCount);

        this.inicializateCart$.next(true);
    }

    priceDiscount(product: any) {

        if (product?.discount?.type === 'percent') {
            let discountNumb = (100 - Number(product?.discount?.value)) / 100;
            return product.price * discountNumb;
        }

        if (product?.discount?.type === 'amount') {
            return product.price - Number(product?.discount?.value);
        }

        if (product?.discount?.type === 'product') {
            return product.price;
        }
        return '';
    }

    priceDiscountItem(product: any) {

        if (product?.discount?.type === 'percent') {
            let discountNumb = (100 - Number(product?.discount?.value)) / 100;
            return product.priceProduct * discountNumb;
        }

        if (product?.discount?.type === 'amount') {
            return product.priceProduct - Number(product?.discount?.value);
        }

        if (product?.discount?.type === 'product') {
            return product.priceProduct;
        }
        return '';
    }

    calculateDiscount(type: string, value: string, price: string, quantity: any) {
        if (type === 'percent') {
            let discountNumb = (100 - Number(value)) / 100;
            return Number(price) * discountNumb;
        }
        if (type === 'amount') {
            let disc = Number(value) * quantity;
            return Number(price) - disc;
        }
        if (type === 'product') {
            let promo;
            if (quantity % 2 === 0) {
                promo = Number(price) / Number(value);
            } else {
                promo = Number(price);
            }
            return promo;
        }
        return '';
    }

    saveShoppingCart(product: any, appointment?: any): Observable<any> {
        let body: any;
        body = {
            businessId: product.id,
            businessname: product.name,
            ownerId: product.ownerId,
            customerId: this.customerId,
            servicesProducts: [
                {
                    id: product.products[0].id,
                    name: product.products[0].name,
                    description: product.products[0].description,
                    comments: product.products[0].comments,
                    price: product.products[0].price,
                    quantity: product.products[0].quantity,
                    dateRegister: product.products[0].dateRegister,
                    timeRegister: product.products[0].timeRegister,
                    subTotal: product.products[0].subTotal,
                    imageURL: product.products[0].imageURL,
                    discount: product.products[0].discount,
                    priceDiscount: product.products[0].priceDiscount,
                    appointment: appointment
                }
            ]
        };
        return this.httpService.post(this.endpoints.saveShoppingCart, body);
    }

    getShoppingCart(): Observable<any> {
        return this.httpService.get(this.endpoints.shoppingCartForCustomer.replace('{id}', this.customerId));
    }

    updateShoppingCart(product: any, appointment?: any): Observable<any> {
        let body = {
            id: this.shoppingCartId,
            servicesProducts: [
                {
                    id: product.id,
                    name: product.name,
                    description: product.description,
                    comments: product.comments,
                    price: product.price,
                    quantity: product.quantity,
                    dateRegister: product.dateRegister,
                    timeRegister: product.timeRegister,
                    subTotal: product.subTotal,
                    imageURL: product.imageURL,
                    discount: product.discount,
                    priceDiscount: product.priceDiscount,
                    appointment: appointment
                }
            ]
        };
        return this.httpService.put(this.endpoints.updateShoppingCart, body);
    }

    deleteProductCart(id: any): Observable<any> {
        let body = {
            id: this.shoppingCartId,
            servicesProducts: [
                {
                    id: id,
                }
            ]
        };
        return this.httpService.put(this.endpoints.updateShoppingCart, body);
    }

    deleteAllShoppingCart(id: any): Observable<any> {
        return this.httpService.delete(this.endpoints.deleteAllShoppingCart.replace('{cartId}', id));
    }
}
