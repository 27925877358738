import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private _bearerToken = "";
    set bearerToken(bearerToken: string) {
        this._bearerToken = bearerToken;
    }

    
    constructor(
        private http: HttpClient,
    ) { }

    private options(options: any) {
        
        const appHeaders = new HttpHeaders()
            .set('Authorization', this._bearerToken)
            .set('Content-Type', 'application/json')

        // Merge headers - priority are app headers
        let headers = new HttpHeaders();

        options?.headers.keys().forEach((key:string) => {
            headers = headers.append(key, options.headers.get(key));
        });

        appHeaders.keys().forEach((key:string) => {
            headers = headers.append(key, appHeaders.get(key) || '');
        });

        return {
            ...options,
            headers: headers,
        };
    }
    
    get(url: string, options?: any) {
        return this.http.get(url, this.options(options));
    }

    post(url: string, body?: any, options?: any) {
        return this.http.post(url, body, this.options(options));
    }

    put(url: string, body?: any, options?: any) {
        return this.http.put(url, body, this.options(options));
    }

    delete(url: string, options?: any) {
        return this.http.delete(url, this.options(options));
    }
}
