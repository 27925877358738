import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EndpointsService {

	constructor() { }

	get categories() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.categories;
	}

	get businesses() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.businesses;
	}

	get business() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.business;
	}

	get businessId() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.businessId;
	}

	get products() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.products;
	}

	get address() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.address;
	}

	get ordersHistory() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.ordersHistory;
	}

	get ordersHistoryListSales() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.ordersHistoryListSales;
	}

	get appointment() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.appointment;
	}

	get appointmentUpdate() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.appointmentUpdate;
	}

	get notificationList() {
		return environment.mapache.hosts.notifications + environment.mapache.endpoints.notifications.list;
	}

	get notificationSeen() {
		return environment.mapache.hosts.notifications + environment.mapache.endpoints.notifications.seen;
	}

	get notificationDelete() {
		return environment.mapache.hosts.notifications + environment.mapache.endpoints.notifications.delete;
	}

	get sales() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.sales;
	}

	get qualify() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.qualify;
	}

	get scoreImage() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.scoreImage;
	}

	get wallets() {
		return environment.mapache.hosts.wallet + environment.mapache.endpoints.wallet.cards;
	}

	get createCard() {
		return environment.mapache.hosts.wallet + environment.mapache.endpoints.wallet.createCard;
	}

	get addBalance() {
		return environment.mapache.hosts.wallet + environment.mapache.endpoints.wallet.addBalance;
	}

	get urlMaps() {
		return environment.urlMaps;
	}

	get receipt() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.receipt;
	}

	get salesMitCard() {
		return environment.mapache.hosts.sales + environment.mapache.endpoints.salesMit.card;
	}

	get salesPragaCard() {
		return environment.mapache.hosts.sales + environment.mapache.endpoints.salesPraga.card;
	}

	get salesMitReference() {
		return environment.mapache.hosts.sales + environment.mapache.endpoints.salesMit.reference;
	}

	get baguuBusiness() {
		return environment.baguuBusines;
	}

	get carTransactions() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.cardTransactions;
	}

	get tag() {
		return environment.events.url + environment.events.tag;
	}

	get help() {
		return environment.mapache.hosts.notifications + environment.mapache.endpoints.help;
	}

	get saveShoppingCart() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.shoppingCart.cartForRequest;
	}

	get shoppingCartForCustomer() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.shoppingCart.cartForCustomer;
	}

	get updateShoppingCart() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.shoppingCart.cartForRequest;
	}

	get deleteAllShoppingCart() {
		return environment.mapache.hosts.ecosistema + environment.mapache.endpoints.shoppingCart.cartForId;
	}
}
