import { Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';

interface ConfirmOptions {
    icon?: string;
    iconClass?: string;
    message?: string;
    confirm?: {
        text?: string;
        handler?: VoidFunction;
    },
    cancel?: {
        hide?: boolean,
        text?: string;
        handler?: VoidFunction;
    },
};

@Injectable({
    providedIn: 'root'
})
export class ConfirmController {
    
    constructor(
        private alertController: AlertController,
    ) { }
    
    async createAndPresent(confirmOptions: ConfirmOptions) {
        const alertOptions:AlertOptions = {
            header: '',
            subHeader: '',
            message: `
                <img src="assets/svg/${ confirmOptions.icon || 'help-icon' }.svg" width="20px" height="20px" class="${ confirmOptions.iconClass || 'alert-icon' }" />
                <p class="alert-message">${confirmOptions.message}</p>
            `,
            cssClass: 'custom-alert-center-buttons',
            buttons: [],
        };

        // According to requirements, cancel is shown first on the left, needed to be inserted first if defined
        if (confirmOptions.cancel) {
            alertOptions.buttons?.push({
                text: confirmOptions.cancel.text || '',
                role: 'cancel',
                cssClass: !confirmOptions.cancel.hide && 'alert-button-no' || undefined,
                handler: confirmOptions.cancel.handler,
            });
        }
        alertOptions.buttons?.push({
            text: confirmOptions.confirm?.text || '',
            role: 'confirm',
            cssClass: 'alert-button-yes',
            handler: confirmOptions.confirm?.handler,
        });

        const alert = await this.alertController.create(alertOptions);
        await alert.present();
    }
}
