import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// Logo
import { LogoComponent } from 'src/app/share/components/logo/logo.component';

import { AddressSelectorComponent } from './address-selector/address-selector.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    declarations: [
        LogoComponent,
        AddressSelectorComponent,
        SearchBoxComponent,
    ],
    imports: [
        CommonModule,
        IonicModule        
    ],
    exports: [
        LogoComponent,
        AddressSelectorComponent,
        SearchBoxComponent,
        QRCodeModule
    ],
})
export class ShareUIComponentModule { }
